import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {
  FormControl,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import { useParams } from 'react-router';
import CategoryService from '../services/category.service';
import ProductServices from '../services/product.service';

export default function EditProductInfo({ onRefresh }) {
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);

  const [categoriesList, setCategoriesList] = React.useState([]);
  const [productInfo, setProductInfo] = React.useState([]);
  const [checked, setChecked] = React.useState(false);

  const handleClickOpen = async () => {
    const categories = await CategoryService.getAllCategories();
    setCategoriesList(categories);
    await ProductServices.getProductInfo(id).then((response) => {
      setProductInfo(response);
      console.log(response);
      if (response.isNew === 1) {
        setChecked(true);
      }
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChangeCategory = (e) => {
    setProductInfo((prev) => ({ ...prev, category_id: e.target.value }));
  };
  const handleChangeName = (e) => {
    const newData = e.target.value;
    setProductInfo((prev) => ({ ...prev, name: newData }));
  };
  const handleChangeChecked = (event) => {
    setChecked(event.target.checked);
  };

  const updateInfo = async (e) => {
    e.preventDefault();
    try {
      const data = {
        name: productInfo.name,
        category_id: productInfo.category_id,
        isNew: checked,
      };
      await ProductServices.updateProductInfo(id, data);
      onRefresh();
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Button onClick={handleClickOpen} variant="contained">
        แก้ไขข้อมูลสินค้า
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        component="form"
        onSubmit={updateInfo}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>แก้ไขข้อมูลสินค้า</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            margin="dense"
            id="color"
            label="ชื่อสินค้า"
            type="text"
            fullWidth
            variant="standard"
            value={productInfo.name}
            onChange={handleChangeName}
          />
          <label className="product-select">เลือกประเภท </label>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              value={productInfo.category_id}
              onChange={handleChangeCategory}
            >
              {categoriesList.map((val, key) => {
                return (
                  <MenuItem value={val.category_id} key={key}>
                    {val.category_name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <hr />
          <FormControlLabel
            control={<Checkbox checked={checked} />}
            onChange={handleChangeChecked}
            label="สินค้าใหม่"
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>ยกเลิก</Button>
          <Button type="submit">บันทึก</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
