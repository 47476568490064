import http from '../http-common';
class ProductServices {
  addNewSize = async (data) => {
    try {
      const response = await http.post('/sizes', data);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };
  changeProductStatus = async (productID) => {
    try {
    } catch (err) {
      console.log(err);
    }
  };

  createProduct = async (productData) => {
    try {
      const response = await http.post('/products', {
        name: productData.name,
        category_id: productData.category_id,
        cover_photo: productData.cover_photo,
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };

  createColor = async (data) => {
    try {
      const response = await http.post('/colors', data);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };

  createProductStock = async (id) => {
    try {
      await http.post(`/stock/product/${id}`);
    } catch (err) {
      console.log(err);
    }
  };

  createSize = async (sizeData) => {
    try {
      await http.post('/sizes', sizeData);
    } catch (err) {
      console.log(err);
    }
  };

  deleteProduct = async (productID) => {
    try {
      await http.delete(`/products/${productID}`);
    } catch (err) {
      console.log(err);
    }
  };

  getAllProducts = async () => {
    try {
      const response = await http.get('/products');
      return response.data;
    } catch (err) {
      console.log(err);
      return null;
    }
  };
  getAllProductSize = async () => {
    try {
      const response = await http.get('/sizes');
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };
  getProductByCat = async (catID) => {
    try {
      const response = await http.get(`/products/category/${catID}`);
      return response.data;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  getProductInfo = async (id) => {
    try {
      const response = await http.get(`/products/${id}`);
      return response.data;
    } catch (err) {
      console.log(err);
      return null;
    }
  };
  getProductSize = async (id) => {
    try {
      const response = await http.get(`sizes/product/${id}`);
      return response.data;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  getProductColor = async (id) => {
    try {
      const response = await http.get(`colors/product/${id}`);
      return response.data;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  getProductStock = async (id) => {
    try {
      const response = await http.get(`/stock/product/${id}`);
      return response.data;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  getAllProductPhotos = async (id) => {
    try {
      const response = await http.get(`uploadPictures/product/${id}`);
      return response.data;
    } catch (err) {
      console.log(err);
      return null;
    }
  };
  getStockByColor = async (color_id) => {
    try {
      const response = await http.get(`/stock/color/${color_id}`);
      return response.data;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  deletePhoto = async (imageID) => {
    try {
      await http.delete(`/products/photo/${imageID}`);
    } catch (err) {
      console.log(err);
    }
  };

  deleteProductColor = async (color_id) => {
    try {
      await http.delete(`/colors/${color_id}`);
    } catch (err) {
      console.log(err);
    }
  };
  deleteStock = async (stockID) => {
    try {
      await http.delete(`/stock/${stockID}`);
    } catch (err) {
      console.log(err);
    }
  };

  updateCover = async (imageID, data) => {
    try {
      await http.put(`/products/cover/${imageID}`, data);
    } catch (err) {
      console.log(err);
    }
  };

  updateProductInfo = async (productID, data) => {
    try {
      await http.put(`/products/${productID}`, data);
    } catch (err) {
      console.log(err);
    }
  };
  updateProductSize = async (sizeID, data) => {
    try {
      await http.put(`/sizes/${sizeID}`, data);
    } catch (err) {
      console.log(err);
    }
  };

  updateStatus = async (productID, status) => {
    try {
      await http.put(`/products/update-status/${productID}`, status);
      //   return true;
    } catch (err) {
      console.log(err);
    }
  };
  updateStock = async (stockID, stockQuantity) => {
    try {
      await http.put(`/stock/${stockID}`, {
        quantity: stockQuantity,
      });
    } catch (err) {
      console.log(err);
    }
  };

  uploadPictures = async (img) => {
    try {
      await http.post('/uploadPictures', img);
    } catch (err) {
      console.log(err);
    }
  };
  uploadToCloud = async (formData) => {
    try {
      const response = await http.post('/image-upload', formData);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };
}
export default new ProductServices();
