import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TextField,
  Button,
  Grid,
  ListItem,
  List,
  Typography,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import Size from '../../components/Size';
import { connect } from 'react-redux';
import { addToCart } from '../../store/actions/cartAction';
import { blockInvalidNumber } from '../../components/BlockInvalidNumber';
import ProductServices from '../../services/product.service';
import SystemService from '../../services/system.service';
import StockService from '../../services/stock.service';

const fadeProperties = {
  infinite: false,
  indicators: true,
  autoplay: false,
};

const ProductDetails = ({ addToCart }) => {
  const { id } = useParams();
  const [orderList, setOrderList] = useState([]);
  const [colorList, setColorList] = useState([]);
  const [allPhotos, setAllPhotos] = useState([]);
  const [productInfo, setProductInfo] = useState([]);
  const [productSize, setProductSize] = useState([]);
  const [productColor, setProductColor] = useState([]);
  const [productStock, setProductStock] = useState([]);
  const [systemStatus, setSystemStatus] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  useEffect(() => {
    const getStatus = async () => {
      const res = await SystemService.getSystemStatus();
      setSystemStatus(res.system_status);
    };
    const getproInfo = async () => {
      await ProductServices.getProductInfo(id).then((response) => {
        setProductInfo(response);
      });
    };
    const getProSize = async () => {
      await ProductServices.getProductSize(id).then((response) => {
        setProductSize(response);
      });
    };
    const getProColor = async () => {
      await ProductServices.getProductColor(id).then((response) => {
        setProductColor(response);
      });
    };
    const getProStock = async () => {
      await ProductServices.getProductStock(id).then((response) => {
        setProductStock(response);
      });
    };
    const getAllPhoto = async () => {
      await ProductServices.getAllProductPhotos(id).then((response) => {
        setAllPhotos(response);
      });
    };
    getStatus();
    getAllPhoto();
    getproInfo();
    getProSize();
    getProColor();
    getProStock();
  }, [id]);

  const order = async (event) => {
	event.preventDefault();
	setOpenBackdrop(true);
    if (orderList.length > 0) {
      const filter = orderList.filter((item) => item.quantity > 0);
      const overStock = [];
      for (let i = 0; i < filter.length; i++) {
        const orderOuantity = filter[i].quantity;
        const res = await StockService.getStockById(filter[i].stock_id);
        if (orderOuantity > res.quantity) {
          const quantity = Number(res.quantity);
          alert(`${res.color_name} เหลือสินค้าเพียง  ${res.quantity}  ชิ้น`);
          overStock.push(filter[i].stock_id);

          const newData = orderList.map((row) => {
            if (row.stock_id === filter[i].stock_id) {
              return {
                ...row,
                quantity,
              };
            }
            return row;
          });

          setOrderList(newData);
          document.getElementById(filter[i].stock_id).value = quantity;
        }
      }
      if (overStock.length > 0) {
		setOpenBackdrop(false);
        return;
      } else {
		//setOpenBackdrop(true);
        const orders = orderList.filter((o) => o.quantity > 0);

        const orderData = {
          product_id: productInfo.product_id,
          product_name: productInfo.name,
          product_size: productSize,
          colors: colorList,
          items: orderList,
        };
        try {
          for (let j = 0; j < orders.length; j++) {
            const stockID = orders[j].stock_id;
            const data = {
              quantity: orders[j].quantity,
            };

            await StockService.decreaseStock(stockID, data);
          }
          addToCart(orderData);
		  setOpenBackdrop(false);
          window.location = '/cart';
        } catch (err) {
          console.log(err);
        }
      }
    }
  };
  const validatefield = () => {
    if (orderList.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const handleOrderUpdate = (e, stock_id, color_id) => {
    const getPrevStock = productStock.filter((s) => s.stock_id === stock_id);
    if (e.target.value > getPrevStock[0].quantity) {
      alert('เหลือสินค้าเพียง ' + getPrevStock[0].quantity + ' ชิ้น');
      e.target.value = getPrevStock[0].quantity;
      const quantity = Number(e.target.value);

      const filter = orderList.filter((o) => o.stock_id === stock_id);
      if (filter.length > 0) {
        const newData = orderList.map((row) => {
          if (row.stock_id === stock_id) {
            return {
              ...row,
              quantity,
            };
          }
          return row;
        });
        setOrderList(newData);
      } else {
        const newOrder = productStock
          .filter((stock) => stock.color_id === color_id)
          .map((row) => {
            if (row.stock_id === stock_id) {
              return {
                ...row,
                quantity,
              };
            } else {
              const quantity = 0;
              return {
                ...row,
                quantity,
              };
            }
          });
		const color = productColor.filter((c) => c.color_id === color_id);
        setColorList((prev) => prev.concat(color));
        setOrderList((prevOrder) => prevOrder.concat(newOrder));
      }
    } else {
      const quantity = Number(e.target.value);
      const filter = orderList.filter((o) => o.stock_id === stock_id);
      if (filter.length > 0) {
        const newData = orderList.map((row) => {
          if (row.stock_id === stock_id) {
            return {
              ...row,
              quantity,
            };
          }
          return row;
        });
        const filter = newData.filter((res) => res.color_id === color_id);
        const validated = verifyOrder(filter, color_id);
        if (validated) {
          setOrderList(newData);
        } else {
          const deleted = orderList.filter(
            (order) => order.color_id !== color_id
          );
          const deletedColor = colorList.filter(
            (color) => color.color_id !== color_id
          );
          setColorList(deletedColor);
          setOrderList(deleted);
        }
      } else {
        if (quantity > 0) {
          const newOrder = productStock
            .filter((stock) => stock.color_id === color_id)
            .map((row) => {
              if (row.stock_id === stock_id) {
                return {
                  ...row,
                  quantity,
                };
              } else {
                const quantity = 0;
                return {
                  ...row,
                  quantity,
                };
              }
            });
          const color = productColor.filter((c) => c.color_id === color_id);
          setOrderList((prevOrder) => prevOrder.concat(newOrder));
          setColorList((prev) => prev.concat(color));
        }
      }
    }
  };
	
  const handleSortStock = (colorId) => {
    const filter = productStock.filter((item) => item.color_id === colorId);
    const sortArr = filter.sort((a, b) => a.size_id - b.size_id);
    return sortArr;
  };

  const verifyStatus = () => {
    if (systemStatus === 1) {
      return (
        <div>
          <Table sx={{ minWidth: 300 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>สี</StyledTableCell>
                {productSize.map((column) => (
                  <StyledTableCell key={column.size_id}>
                    {column.size_name}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {productColor.map((row) => (
                <TableRow key={row.color_id}>
                  <TableCell>{row.color_name}</TableCell>
                  {handleSortStock(row.color_id).map((size) => (
                    <TableCell key={size.stock_id}>
                      {verifyQuantity(size)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Button
            sx={{ marginTop: 2 , mb: 6 }}
            fullWidth
            disabled={validatefield()}
            onClick={order}
            variant="contained"
          >
            เพิ่มในตะกร้า
          </Button>
        </div>
      );
    }
  };

  const verifyQuantity = (size) => {
    if (size.quantity > 0) {
      const inputID = size.stock_id.toString();
      return (
        <TextField
          type="number"
          id={inputID}
	  	  keyboardtype="numeric"
          InputProps={{ inputProps: { min: 0 } }}
          onKeyDown={blockInvalidNumber}
          onChange={(e) => handleOrderUpdate(e, size.stock_id, size.color_id)}
        />
      );
    } else {
      return <Typography style={{ color: 'red' }}>หมด</Typography>;
    }
  };
  const verifyOrder = (data, colorID) => {
    const orders = data.filter((order) => order.quantity > 0);
    if (orders.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  return (
	<div>
    <div className="sarid-container">
      <Grid container spacing={1} >
        <Grid item md={6} xs={12}>
          <div className="slide-container">
            <Fade {...fadeProperties}>
              {allPhotos.map((slideImage, index) => (
                <div className="each-slide" key={index}>
                  <div
                    style={{ backgroundImage: `url(${slideImage.image_url})` }}
                  ></div>
                </div>
              ))}
            </Fade>
          </div>
        </Grid>
        <Grid item md={5} xs={12}>
          <List>
            <ListItem>ชื่อสินค้า: {productInfo.name}</ListItem>

            <ListItem>
              <Size data={productSize} />
            </ListItem>
          </List>
        </Grid>
      </Grid>
      {verifyStatus()}
    </div>
	<Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
	</div>
  );
};
export default connect(null, { addToCart })(ProductDetails);
