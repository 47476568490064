import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Moment from 'moment';
import Slide from '@mui/material/Slide';
import { Link } from 'react-router-dom';
import {
  Grid,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  ListItem,
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { StyledTableCell } from '../components/StyledTableCell';
import OrderService from '../services/order.service';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OrderDetailsDialog = ({ orderID }) => {
  const [open, setOpen] = React.useState(false);
  const [orderInfo, setOrderInfo] = React.useState([]);
  const [orderDetails, setOrderDetails] = React.useState([]);

  const handleClickOpen = async () => {
    const response = await OrderService.getOrderById(orderID);
    const details = JSON.parse(response.order_details);
    setOrderDetails(details);
    setOrderInfo(response);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="space-bt-left">
      <Button onClick={handleClickOpen}>รายละเอียด</Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <ArrowBackIosNewIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              รายละเอียดออเดอร์
            </Typography>
          </Toolbar>
        </AppBar>
        {/* <List>
          <Typography>คำสั่งซื้อที่: {orderID}</Typography>
        </List> */}

        <List>
          <div className="sarid-container">
            <Typography>คำสั่งซื้อที่: {orderID}</Typography>
            <ListItem>
              <Typography>
                วันที่สั่งซื้อ:{' '}
                {Moment(orderInfo.created_at).format('DD/MM/YYYY HH:mm:ss')}
              </Typography>
            </ListItem>
            {orderDetails.map((o) => (
              <div className="cart-item" key={o.product_id}>
                <Grid item xs={12} sm container>
                  <Grid item xs container spacing={2}>
                    <Grid item sx={{ marginTop: 1, marginBottom: 1 }}>
                      <Typography>{o.product_name}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
				<div className="table-container">
                <Table sx={{ minWidth: 300 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>สี</StyledTableCell>
                      {o.product_size.map((column) => (
                        <StyledTableCell key={column.size_id}>
                          {column.size_name}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {o.colors.map((row) => (
                      <TableRow key={row.color_id}>
                        <TableCell>{row.color_name}</TableCell>
                        {o.items
                          .filter((stock) => stock.color_id === row.color_id)
                          .map((size) => (
                            <TableCell key={size.stock_id}>
                              {size.quantity}
                            </TableCell>
                          ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
				</div>
              </div>
            ))}
            <Grid item xs={12} sm container sx={{ marginTop: 4 }}>
              {orderInfo.bill_id ? (
                <Button
                  fullWidth
                  variant="contained"
                  style={{ backgroundColor: '#9ACD32', color: 'black' }}
                  component={Link}
                  to={`/invoice/${orderInfo.bill_id}`}
                >
                  ดูใบเสร็จ
                </Button>
              ) : null}
            </Grid>
          </div>
        </List>
      </Dialog>
    </div>
  );
};
export default OrderDetailsDialog;
