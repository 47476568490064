import React, { useEffect, useState } from 'react';

import { Typography, Box, Grid } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Button,
} from '@mui/material';
import { useParams } from 'react-router';
import OrderService from '../../services/order.service';
import { StyledTableCell } from '../../components/StyledTableCell';
import Moment from 'moment';
import { Link } from 'react-router-dom';

const OrderResponse = () => {
  const { id } = useParams();
  const [orderData, setOrderData] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  useEffect(() => {
    const getOrder = async () => {
      const order = await OrderService.getOrderById(id);
      const details = JSON.parse(order.order_details);
      console.log('order', order);
      setOrderData(order);
      setOrderDetails(details);
    };
    getOrder();
  }, [id]);
  if (id === undefined) {
    return (
      <div className="sarid-container">
        <Box
          sx={{
            marginTop: 8,
          }}
          justifyContent="center"
        >
          <div>
            <Typography>
              <CancelIcon
                sx={{
                  marginRight: 2,
                  color: 'red',
                }}
              />
              สั่งซื้อสินค้าไม่สำเร็จ
            </Typography>
          </div>
        </Box>
        <Box
          sx={{
            marginTop: 2,
          }}
          justifyContent="center"
        >
          <Grid
            sx={{
              marginTop: 2,
            }}
          ></Grid>
        </Box>
      </div>
    );
  }
  return (
    <div className="sarid-container">
      <Box
        sx={{
          marginTop: 8,
        }}
        justifyContent="center"
      >
        <div>
          <Typography>
            <CheckCircleIcon
              sx={{
                marginRight: 2,
                color: 'green',
              }}
            />
            สั่งซื้อสินค้าสำเร็จ
          </Typography>
        </div>
      </Box>
      <Box
        sx={{
          marginTop: 2,
        }}
        justifyContent="center"
      >
        <Typography>คำสั่งซื้อเลขที่: {orderData.order_id}</Typography>

        <Grid
          sx={{
            marginTop: 2,
          }}
        ></Grid>
      </Box>
      <Grid container sx={{ marginTop: 1 }}>
        <Grid>
          <Typography>วันที่สั่งซื้อ: </Typography>
        </Grid>
        <Grid sx={{ marginLeft: 2 }}>
          <Typography>
            {Moment(orderData.created_at).format('DD/MM/YYYY HH:mm:ss')}
          </Typography>
        </Grid>
      </Grid>
      {orderDetails.map((o) => (
        <div className="cart-item" key={o.product_id}>
          <Grid item xs={12} sm container>
            <Grid item xs container spacing={2}>
              <Grid item sx={{ marginTop: 1, marginBottom: 1 }}>
                <Typography>{o.product_name}</Typography>
              </Grid>
            </Grid>
          </Grid>
		  <div className="table-container">
          <Table sx={{ minWidth: 250 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>สี</StyledTableCell>
                {o.product_size.map((column) => (
                  <StyledTableCell key={column.size_id}>
                    {column.size_name}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {o.colors.map((row) => (
                <TableRow key={row.color_id}>
                  <TableCell>{row.color_name}</TableCell>
                  {o.items
                    .filter((stock) => stock.color_id === row.color_id)
                    .map((size) => (
                      <TableCell key={size.stock_id}>
                        <Typography sx={{ marginTop: 1, marginRight: 1 }}>
                          {size.quantity}
                        </Typography>
                      </TableCell>
                    ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
		  </div>
        </div>
      ))}
      <Grid item xs={12} sm container sx={{ marginTop: 4 }}>
        <Button
		  sx={{ mb: 6 }}
          component={Link}
          to={'/'}
          variant="contained"
          size="small"
          fullWidth
        >
          กลับสู่หน้าหลัก
        </Button>
      </Grid>
    </div>
  );
};

export default OrderResponse;
