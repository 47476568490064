import http from '../http-common';

class StockService {
  async createStock(colorData) {
    try {
      await http.post('/stock', colorData);
    } catch (err) {
      console.log(err);
    }
  }
  //Get all stock
  async getAllStock() {
    try {
      const response = await http.get('/stock');
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }
  //Update stock after order(add to cart/decrease)
  async decreaseStock(stockID, data) {
    try {
      await http.put(`/stock/decrease-quantity/${stockID}`, data);
    } catch (err) {
      console.log(err);
    }
  }
  //Update stock(increment)
  async incrementStock(stockID, data) {
    try {
      await http.put(`/stock/increment-quantity/${stockID}`, data);
    } catch (err) {
      console.log(err);
    }
  }
  //Update stock data (for admin)
  async updateStockData(stockID, data) {
    try {
      await http.put(`/stock/${stockID}`, data);
    } catch (err) {
      console.log(err);
    }
  }

  async getStockById(stockID) {
    try {
      const response = await http.get(`/stock/${stockID}`);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }
}

export default new StockService();
