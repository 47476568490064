import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useParams } from 'react-router';
import ProductServices from '../services/product.service';
import StockService from '../services/stock.service';
import { FormControl, MenuItem, Select } from '@mui/material';
import httpCommon from '../http-common';

export default function AddSizeDialog({ size, onRefresh }) {
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);
  const [newSize, setNewSize] = React.useState('none');
  const [chest, setChest] = React.useState('');
  const [price, setPrice] = React.useState(0);
  const [sizeList, setSizeList] = React.useState([]);

  React.useEffect(() => {
    const getSizeList = () => {
      const allSize = ['XS', 'S', 'M', 'L', 'XL', 'XXL'];
      let newList = [];
      for (let i = 0; i < allSize.length; i++) {
        const filterVal = size.filter((item) => item.size_name === allSize[i]);
        if (filterVal.length === 0) {
          newList.push(allSize[i]);
        } else {
        }
      }
      setSizeList(newList);
    };
    getSizeList();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChangeSize = (e) => {
    setNewSize(e.target.value);
  };
  const addSize = async (e) => {
    e.preventDefault();
    const data = {
      size_name: newSize,
      chest: chest,
      price: price,
      product_id: id,
    };
    try {
      setOpen(false);
      const newSizeResponse = await ProductServices.addNewSize(data);
      if (newSize) {
        const data = {
          size_id: newSizeResponse.id,
          product_id: newSizeResponse.product_id,
        };
        await httpCommon.post('/stock/size', data);
        onRefresh();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="space-bt-left">
      <Button fullWidth variant="contained" onClick={handleClickOpen}>
        เพิ่มขนาด
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        component="form"
        onSubmit={addSize}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>เพิ่มขนาด</DialogTitle>
        <DialogContent>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select value={newSize} onChange={handleChangeSize}>
              <MenuItem value="none">โปรดเลือกขนาด</MenuItem>
              {sizeList.map((val, key) => {
                return (
                  <MenuItem value={val} key={key}>
                    {val}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            id="color"
            label="รอบอก"
            type="text"
            fullWidth
            variant="standard"
            onChange={(event) => setChest(event.target.value)}
          />
          <TextField
            margin="dense"
            id="color"
            label="ราคา"
            type="number"
            fullWidth
            variant="standard"
            onChange={(event) => setPrice(event.target.value)}
          />

          {/* <DialogContentText>เพิ่มสีของสินค้า: {productName}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="color"
            label="สี"
            type="text"
            fullWidth
            variant="standard"
            onChange={(event) => setNewColor(event.target.value)}
          /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>ยกเลิก</Button>
          <Button type="submit" disabled={newSize === 'none'}>
            เพิ่มขนาด
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
