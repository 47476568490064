import http from '../http-common';
class BillService {
  async createBill(billData) {
    try {
      const response = await http.post('/bill', billData);
      console.log(response.data);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }

  async getBill(billID) {
    try {
      const response = await http.get(`/bill/${billID}`);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }
  async getPrice(stockID) {
    try {
      const response = await http.get(`/bill/stock/${stockID}`);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }
}
export default new BillService();
