import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AuthService from '../../services/auth.service';
import ForgotPasswordDialog from '../../components/ForgotPasswordDialog';
import { Divider } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import liff from '@line/liff';
import { connect } from 'react-redux';

const theme = createTheme();
const eye = <FontAwesomeIcon icon={faEye} />;
const eyeClose = <FontAwesomeIcon icon={faEyeSlash} />;


const Signin = ({ cartItems }) => {
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [errMsg, setErrMsg] = React.useState('');
  const formRef = React.useRef();
  const [passwordShown, setPasswordShown] = React.useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const res = await AuthService.login(username, password);
      if (res.status !== 'success') {
        setErrMsg(res.status);
      } else {
        const user = await AuthService.getCurrentUser();
        if (user) {
          if (user.role === 'admin') {
            window.location = '/admin';
          } else {
           	if (cartItems.length > 0) {
              window.location = '/cart';
            } else {
              window.location = '/';
            }
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const initLine = () => {
    liff.init(
      { liffId: '1656911262-me8ZZQ2B' },
      () => {
        if (liff.isLoggedIn()) {
          window.location = '/';
        } else {
          try {
            liff.login();
          } catch (err) {
            console.log(err);
          }
        }
      },
      (err) => console.err(err)
    );
  };
  const loginWithLine = (e) => {
    e.preventDefault();
    initLine();
  };
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            เข้าสู่ระบบ
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
            ref={formRef}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="อีเมล"
                  name="username"
                  autoComplete="email"
                  autoFocus
                  onChange={(event) => setUsername(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <div className="pass-wrapper">
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label="รหัสผ่าน"
                    type={passwordShown ? 'text' : 'password'}
                    id="password"
                    onChange={(event) => setPassword(event.target.value)}
                  />
                  <i onClick={togglePasswordVisiblity}>
                    {passwordShown ? eyeClose : eye}
                  </i>
                </div>
              </Grid>

              <div>
                <span style={{ color: 'red' }}>{errMsg}</span>
              </div>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => formRef.current.reportValidity()}
            >
              เข้าสู่ระบบ
            </Button>

            <Grid container>
              <Grid item xs>
                <ForgotPasswordDialog />
              </Grid>
              <Grid item>
                <div>
                  <Link href="/register" variant="body2">
                    {'ยังไม่ได้เป็นสมาชิก? สมัครสมาชิก'}
                  </Link>
                </div>
              </Grid>
            </Grid>
            <Divider sx={{ marginTop: 5 }} />
            <Grid sx={{ marginTop: 5 }}>
               <div className="line-btn">
                <span className="icon"></span>
                <button className="line-button" onClick={loginWithLine}>
                    <Typography style={{ color: 'white' }}>
                    เข้าสู่ระบบโดยใช้ไลน์
                  </Typography>
                </button>
              </div>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
export default connect((state) => ({
  cartItems: state.cart.cartItems,
}))(Signin);
