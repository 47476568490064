import { Typography, Card, CardContent } from '@mui/material';

import React, { useEffect, useState } from 'react';
import OrderService from '../../../../services/order.service';
import Grid from '@mui/material/Grid';
import { useParams } from 'react-router';
import Moment from 'moment';
import OrderDetailsDialog from '../../../../components/OrderDetailsDialog';

const UserOrder = () => {
  const [allOrders, setAllOrders] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    const getOrders = async () => {
      const orders = await OrderService.getOrderByUserId(id);
      setAllOrders(orders);
    };
    getOrders();
  }, [id]);

  const checkOrders = () => {
    if (allOrders.length > 0) {
      return (
        <div className="sarid-container">
          {allOrders.map((order) => (
            <div className="information-container" key={order.order_id}>
              <Grid>
                <Typography>คำสั่งซื้อเลขที่: {order.order_id}</Typography>
              </Grid>
              <Grid>
                <Typography>
                  วันที่สั่งซื้อ:{' '}
                  {Moment(order.created_at).format('DD/MM/YYYY HH:mm:ss')}
                </Typography>
              </Grid>
              <Grid container>
                <Grid item sx={{ marginRight: 1 }}>
                  <Typography>สถานะ:</Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={
                      order.order_status === 'complete'
                        ? { color: 'green' }
                        : { color: 'orange' }
                    }
                  >
                    {order.order_status}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs container spacing={2}>
                <Grid item xs></Grid>
                <OrderDetailsDialog orderID={order.order_id} />
              </Grid>
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <div className="sarid-container">
          <Card sx={{ minWidth: 300 }}>
            <CardContent sx={{ justifyContent: 'center' }}>
              <Typography style={{ color: 'blue' }}>
                ยังไม่มีรายการสั่งซื้อ
              </Typography>
            </CardContent>
          </Card>
        </div>
      );
    }
  };
  return (
    <div>
      <div className="sarid-container">
        <h1>คำสั่งซื้อทั้งหมดของคุณ</h1>
      </div>

      {checkOrders()}
    </div>
  );
};

export default UserOrder;
