import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom';
import ProductServices from '../../services/product.service';

const TShirt = () => {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    const poloProducts = async () => {
      const response = await ProductServices.getProductByCat(4);
      const productAvailable = response.filter(
        (product) => product.status === 'Available'
      );
      setProducts(productAvailable);
    };
    poloProducts();
  }, []);
  const checkProduct = () => {
    if (products.length > 0) {
      return (
        <Grid container spacing={4}>
          {products.map((card) => (
            <Grid
              item
              key={card.product_id}
              xs={12}
              sm={6}
              md={4}
              component={Link}
              to={{
                pathname: `/product/${card.product_id}`,
                query: { id: card.product_id },
              }}
              style={{ textDecoration: 'none' }}
              underline="none"
            >
              <div className="product-card">
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <CardMedia
                    component="img"
                    image={card.image_url}
                    alt="random"
                  />
                  {card.isNew === 1 ? (
                    <Typography
                      className="product-card-name"
                      style={{ color: 'white' }}
                    >
                      New!!!
                    </Typography>
                  ) : null}

                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom component="h1">
                      {card.name}
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            </Grid>
          ))}
        </Grid>
      );
    } else {
      return (
        <div className="sarid-container">
          <Card sx={{ minWidth: 350 }}>
            <CardContent>
              <Typography>ยังไม่มีสินค้าประเภทเสื้อยืดในขณะนี้</Typography>
            </CardContent>
            <CardActions sx={{ mb: 0 }}>
              <Button
                component={Link}
                to={'/'}
                variant="contained"
                size="big"
                fullWidth
              >
                กลับไปยังหน้าหลัก
              </Button>
            </CardActions>
          </Card>
        </div>
      );
    }
  };
  return (
    <div className="container">
      <Container sx={{ py: 8 }} maxWidth="md">
        {checkProduct()}
      </Container>
    </div>
  );
};

export default TShirt;
