import React from 'react';
import './App.css';
import ResponsiveAppBar from './components/Navbar';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import Register from './pages/register';
import Signin from './pages/signin';
import AdminHome from './pages/admin';
import Home from './pages';
import AddProduct from './pages/addProduct';
import EditProduct from './pages/admin/product/_id';
import ProductDetails from './pages/products/_id';
import AddStock from './pages/addProduct/addStock/_id';
import { Provider } from 'react-redux';
import store from './store';
import Cart from './pages/cart';
import OrderResponse from './pages/order/_id';
import Orders from './pages/admin/orders';
import OrderDetails from './pages/admin/orders/_id';
import InvoiceDetails from './pages/invoice/_id';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Shirt from './pages/products/shirt';
import Polo from './pages/products/polo';
import Hawaii from './pages/products/hawaii';
import TShirt from './pages/products/t-shirt';
import SaleSystem from './pages/admin/system';
import OrderHistory from './pages/admin/orders/history';
import OrderHistoryDetails from './pages/admin/orders/history/_id';
import AdminAccount from './pages/admin/account';
import UserAccount from './pages/user/account';
import Contact from './pages/contact';
import ShopInformation from './pages/admin/shop-info';
import Stock from './pages/admin/stock';
import UserOrder from './pages/user/order-history/_id';
import Sales from './pages/admin/sales';
// import LineLogin from './pages/signin/line';

const theme = createTheme({
  palette: {
    primary: {
      main: '#17BCBC',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Router>
          <ResponsiveAppBar />
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/shirt"></Route>
            <Route path="/register" element={<Register />}></Route>
            <Route path="/signin" element={<Signin />}></Route>
            <Route path="/admin" element={<AdminHome />}></Route>
            <Route path="/addProduct" element={<AddProduct />}></Route>
            <Route path="/admin/product/:id" element={<EditProduct />}></Route>
			<Route path="/admin/stock" element={<Stock />}></Route>
		    <Route path="/admin/sales" element={<Sales />}></Route>
            <Route path="/product/:id" element={<ProductDetails />}></Route>
            <Route path="/product/shirt" element={<Shirt />}></Route>
            <Route path="/product/polo" element={<Polo />}></Route>
            <Route path="/product/hawaii" element={<Hawaii />}></Route>
			<Route path="/product/t-shirt" element={<TShirt />}></Route>
            <Route
              path="/addProduct/addStock/:id"
              element={<AddStock />}
            ></Route>
            <Route path="/cart" element={<Cart />}></Route>
            <Route path="/contact" element={<Contact />}></Route>
            
            <Route path="/user/account" element={<UserAccount />}></Route>
			<Route
              path="/user/order-history/:id"
              element={<UserOrder />}
            ></Route>
            <Route path="/order/:id" element={<OrderResponse />}></Route>
            <Route path="/invoice/:id" element={<InvoiceDetails />}></Route>
            <Route path="/admin/orders/:id" element={<OrderDetails />}></Route>
            <Route path="/admin/system" element={<SaleSystem />}></Route>
            {/* <Route path="/signin/line" element={<LineLogin />}></Route> */}
            <Route
              path="/admin/shop-info"
              element={<ShopInformation />}
            ></Route>
            <Route path="/admin/account" element={<AdminAccount />}></Route>
            <Route
              path="/admin/orders/history"
              element={<OrderHistory />}
            ></Route>
            <Route
              path="/admin/orders/history/:id"
              element={<OrderHistoryDetails />}
            ></Route>
            <Route path="/admin/orders" element={<Orders />}></Route>
          </Routes>
        </Router>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
