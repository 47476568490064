import React, { Component, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TextField,
} from '@mui/material';
import { useParams } from 'react-router';
import ProductServices from '../services/product.service';
import { blockInvalidNumber } from './BlockInvalidNumber';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditSizeDialog({ onRefresh }) {
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);
  const [sizeChartData, setSizeChartData] = useState([]);

  const handleClickOpen = async () => {
    await ProductServices.getProductSize(id).then((response) => {
      setSizeChartData(response);
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChestUpdate = (size, chest) => {
    const newData = sizeChartData.map((row) => {
      if (row.size_name === size) {
        return {
          ...row,
          chest,
        };
      }
      return row;
    });
    setSizeChartData(newData);
  };
  const handlePriceUpdate = (size, price) => {
    const newData = sizeChartData.map((row) => {
      if (row.size_name === size) {
        return {
          ...row,
          price,
        };
      }
      return row;
    });
    setSizeChartData(newData);
  };
  const updateSize = async () => {
    try {
      for (let i = 0; i < sizeChartData.length; i++) {
        const sizeID = sizeChartData[i].size_id;
        const data = {
          chest: sizeChartData[i].chest,
          price: sizeChartData[i].price,
        };
        await ProductServices.updateProductSize(sizeID, data);
      }
      onRefresh();
      setOpen(false);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        แก้ไขขนาดและราคา
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              แก้ไขขนาดและราคา
            </Typography>
            <Button autoFocus color="inherit" onClick={updateSize}>
              บันทึก
            </Button>
          </Toolbar>
        </AppBar>
        <List>
          <div className="sarid-container">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Size</TableCell>
                  <TableCell align="center">รอบอก</TableCell>
                  <TableCell align="center">ราคา</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sizeChartData.map((row) => (
                  <SizeRow
                    key={row.size_id}
                    data={row}
                    onChestUpdate={handleChestUpdate}
                    onPriceUpdate={handlePriceUpdate}
                  />
                ))}
              </TableBody>
            </Table>
          </div>
        </List>
      </Dialog>
    </div>
  );
}
class SizeRow extends Component {
  handleChange = (e) => {
    this.props.onChestUpdate(this.props.data.size_name, e.target.value);
  };
  handleChangePrice = (e) => {
    this.props.onPriceUpdate(this.props.data.size_name, e.target.value);
  };

  render() {
    const {
      data: { size_name, chest, price },
    } = this.props;

    return (
      <TableRow>
        <TableCell component="th" scope="row">
          {size_name}
        </TableCell>
        <TableCell align="center">
          <TextField value={chest} type="number" onKeyDown={blockInvalidNumber} onChange={this.handleChange} />
        </TableCell>
        <TableCell align="center">
          <TextField
            type="number"
            value={price}
			onKeyDown={blockInvalidNumber}
            onChange={this.handleChangePrice}
          />
        </TableCell>
      </TableRow>
    );
  }
}
