import { Button, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import React, { useEffect, useState } from 'react';
import WarningStopSaleDialog from '../../components/WarningStopSaleDialog';
import ProductServices from '../../services/product.service';
import AuthService from '../../services/auth.service';

const AdminHome = () => {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    const adminAuth = async () => {
      await AuthService.isAdmin();
    };
    const allProducts = async () => {
      await ProductServices.getAllProducts().then((response) => {
        setProducts(response);
      });
    };
    adminAuth();
    allProducts();
  }, []);

  const reloadProducts = async () => {
    await ProductServices.getAllProducts().then((response) => {
      setProducts(response);
    });
  };
  const Status = ({ status, productID, productName }) => {
    if (status === 'Available') {
      return (
        <WarningStopSaleDialog
          title="คุณแน่ใจว่าต้องการหยุดการขายสินค้านี้ ?"
          message="ต้องการหยุดการขาย "
          data={productName}
          button="หยุดการขาย"
          status={status}
          onWarningSubmit={() => stopSale(productID)}
        ></WarningStopSaleDialog>
      );
    } else {
      return (
        <WarningStopSaleDialog
          title="คุณแน่ใจว่าต้องการเปิดการขายสินค้านี้ ?"
          message="ต้องการเปิดการขาย "
          data={productName}
          button="เปิดขาย"
          status={status}
          onWarningSubmit={() => sale(productID)}
        ></WarningStopSaleDialog>
      );
    }
  };

  const stopSale = async (productID) => {
    const data = {
      status: 'Unavailable',
    };
    try {
      await ProductServices.updateStatus(productID, data);
      reloadProducts();
    } catch (err) {
      console.log(err);
    }
  };
  const sale = async (productID) => {
    const data = {
      status: 'Available',
    };
    try {
      await ProductServices.updateStatus(productID, data);
      reloadProducts();
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="sarid-container">
      <h1>รายการสินค้าทั้งหมด</h1>
      {products.map((product) => (
        <div className="admin-product-item" key={product.product_id}>
          <Grid container spacing={2}>
            <Grid item>
              <img src={product.image_url} alt={product.name} height="150" />
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1" component="div">
                    {product.name}
                  </Typography>
                </Grid>
                <Grid item>
                  <Status
                    status={product.status}
                    productID={product.product_id}
                    productName={product.name}
                  />
                </Grid>
              </Grid>
              <Grid item>
                <Button
                  component={Link}
                  to={{
                    pathname: `product/${product.product_id}`,
                    query: { id: product.product_id },
                  }}
                  variant="outlined"
                >
                  แก้ไขสินค้า
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      ))}
    </div>
  );
};

export default AdminHome;
