import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import AuthenService from '../services/auth.service';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import { itemTotal } from './cart';
import liff from '@line/liff/dist/lib';

const pages = [
  { item: 'สินค้าทั้งหมด', key: 'all', url: '/' },
  { item: 'เสื้อเชิ้ต', key: 'shirt', url: '/product/shirt' },
  { item: 'เสื้อโปโล', key: 'polo', url: '/product/polo' },
  { item: 'เสื้อฮาวาย', key: 'hawaii', url: '/product/hawaii' },
  { item: 'เสื้อยืด', key: 't-shirt', url: '/product/t-shirt' },
  { item: 'ติดต่อเรา', key: 'contact', url: '/contact' },
];
const guestPages = [
  { item: 'สมัครสมาชิก', key: 'register', url: '/register' },
  { item: 'เข้าสู่ระบบ', key: 'signin', url: '/signin' },
];
const bossMenu = [
  { item: 'รายการสินค้า', key: 'products', url: '/admin' },

  { item: 'Order', key: 'orders', url: '/admin/orders' },
  {
    item: 'คำสั่งซื้อย้อนหลัง',
    key: 'orders-history',
    url: '/admin/orders/history',
  },
  { item: 'เพิ่มสินค้าใหม่', key: 'addProduct', url: '/addProduct' },
  { item: 'ข้อมูลร้าน', key: 'shopInfo', url: '/admin/shop-info' },
  { item: 'ตั้งค่าระบบ', key: 'setting-system', url: '/admin/system' },
  { item: 'Stock', key: 'stock', url: '/admin/stock' },
  { item: 'ยอดขาย', key: 'sales', url: '/admin/sales' },
];
const adminMenu = [
  { item: 'รายการสินค้า', key: 'products', url: '/admin' },

  { item: 'Order', key: 'orders', url: '/admin/orders' },
  {
    item: 'คำสั่งซื้อย้อนหลัง',
    key: 'orders-history',
    url: '/admin/orders/history',
  },

  { item: 'เพิ่มสินค้าใหม่', key: 'addProduct', url: '/addProduct' },
  { item: 'ข้อมูลร้าน', key: 'shopInfo', url: '/admin/shop-info' },
  { item: 'ตั้งค่าระบบ', key: 'setting-system', url: '/admin/system' },
  { item: 'Stock', key: 'stock', url: '/admin/stock' },
];
const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 4,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

const ResponsiveAppBar = () => {
  const [isUser, setisUser] = useState(false);
  const [isAdmin, setisAdmin] = useState(false);
  const [isBoss, setisBoss] = useState(false);
  const [isLineAccount, setisLineAccount] = useState(false);
  const [userInfo, setUserInfo] = useState([]);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [lineInfo, setLineInfo] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const getUser = async () => {
      const user = await AuthenService.getCurrentUser();
      if (user) {
        setUserInfo(user);
        const role = user.role;
         if (role !== undefined && role === 'boss') {
          setisAdmin(false);
          setisBoss(true);
          setisUser(false);
        } else if (role !== undefined && role === 'admin') {
          setisAdmin(true);
          setisBoss(false);
          setisUser(false);
        } else {
          if (role === undefined) {
            setisLineAccount(true);
            setLineInfo(user);
          } else {
            setisAdmin(false);
            setisUser(true);
          }
        }
      } else {
		setisBoss(false);
        setisAdmin(false);
        setisLineAccount(false);
        setisUser(false);
        setUserInfo([]);
      }
    };
    const getCartTotal = () => {
      const total = itemTotal();
      setTotal(total);
    };
    getUser();
    getCartTotal();
  }, []);

  const logout = () => {
    handleCloseNavMenu();
    localStorage.removeItem('user');
    window.location.reload();
    window.location = '/';
  };

  const lineLogout = async() => {
	  try {
		  await liff.init({ liffId: '1656911262-me8ZZQ2B' }).catch(err=>{throw err});
		  liff.logout();
          window.location = '/';
	  }catch(err) {
		  console.log(err)
	  }
   
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  if (isBoss) {
    return (
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {bossMenu.map((menu) => (
                  <MenuItem
                    key={menu.key}
                    onClick={handleCloseNavMenu}
                    component={Link}
                    to={menu.url}
                  >
                    <Typography textAlign="center">{menu.item}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
            >
              Sarid Shirt
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {bossMenu.map((menu) => (
                <Button
                  key={menu.key}
                  onClick={handleCloseNavMenu}
                  component={Link}
                  to={menu.url}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  {menu.item}
                </Button>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="ตั้งค่า">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    alt={userInfo.username}
                    src="/static/images/avatar/2.jpg"
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem
                  onClick={handleCloseUserMenu}
                  component={Link}
                  to="/admin/account"
                >
                  <Typography textAlign="center">บัญชี</Typography>
                </MenuItem>
                <MenuItem onClick={logout}>
                  <Typography textAlign="center">ออกจากระบบ</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    );
  } else if (isAdmin) {
    return (
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
   
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {adminMenu.map((menu) => (
                  <MenuItem
                    key={menu.key}
                    onClick={handleCloseNavMenu}
                    component={Link}
                    to={menu.url}
                  >
                    <Typography textAlign="center">{menu.item}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
            >
              Sarid Shirt
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {adminMenu.map((menu) => (
                <Button
                  key={menu.key}
                  onClick={handleCloseNavMenu}
                  component={Link}
                  to={menu.url}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  {menu.item}
                </Button>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="ตั้งค่า">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    alt={userInfo.username}
                    src="/static/images/avatar/2.jpg"
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem
                  onClick={handleCloseUserMenu}
                  component={Link}
                  to="/admin/account"
                >
                  <Typography textAlign="center">บัญชี</Typography>
                </MenuItem>
                <MenuItem onClick={logout}>
                  <Typography textAlign="center">ออกจากระบบ</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    );
  } else if (isUser) {
    return (
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  <MenuItem
                    key={page.key}
                    onClick={handleCloseNavMenu}
                    component={Link}
                    to={page.url}
                  >
                    <Typography textAlign="center">{page.item}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 0.5, display: { xs: 'flex', md: 'none' } }}
            >
              Sarid Shirt
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page) => (
                <Button
                  key={page.key}
                  onClick={handleCloseNavMenu}
                  component={Link}
                  to={page.url}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  {page.item}
                </Button>
              ))}
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="ตะกร้าสินค้า">
                <IconButton sx={{ mr: 4 }} component={Link} to="/cart">
                  <StyledBadge badgeContent={total} color="secondary">
                    <ShoppingCartIcon />
                  </StyledBadge>
                </IconButton>
              </Tooltip>
              <Tooltip title="ตั้งค่า">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    alt={userInfo.username}
                    src="/static/images/avatar/2.jpg"
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem
                  onClick={handleCloseUserMenu}
                  component={Link}
                  to={{
                    pathname: `/user/order-history/${userInfo.user_id}`,
                  }}
                >
                  <Typography>ประวัติการสั่งซื้อ</Typography>
                </MenuItem>
                <MenuItem
                  onClick={handleCloseUserMenu}
                  component={Link}
                  to="/user/account"
                >
                  <Typography textAlign="center">บัญชี</Typography>
                </MenuItem>
                <MenuItem onClick={logout}>
                  <Typography textAlign="center">ออกจากระบบ</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    );
  } else if (isLineAccount) {
    return (
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  <MenuItem
                    key={page.key}
                    onClick={handleCloseNavMenu}
                    component={Link}
                    to={page.url}
                  >
                    <Typography textAlign="center">{page.item}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 0.5, display: { xs: 'flex', md: 'none' } }}
            >
              Sarid Shirt
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page) => (
                <Button
                  key={page.key}
                  onClick={handleCloseNavMenu}
                  component={Link}
                  to={page.url}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  {page.item}
                </Button>
              ))}
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="ตะกร้าสินค้า">
                <IconButton sx={{ mr: 4 }} component={Link} to="/cart">
                  <StyledBadge badgeContent={total} color="secondary">
                    <ShoppingCartIcon />
                  </StyledBadge>
                </IconButton>
              </Tooltip>
              <Tooltip title={lineInfo.displayName}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    alt={lineInfo.displayName}
                    src={lineInfo.pictureUrl}
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
				<MenuItem
                  onClick={handleCloseUserMenu}
                  component={Link}
                  to={{
                    pathname: `/user/order-history/${lineInfo.userId}`,
                  }}
                >
                  <Typography>ประวัติการสั่งซื้อ</Typography>
                </MenuItem>
                <MenuItem onClick={lineLogout}>
                  <Typography textAlign="center">ออกจากระบบ</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    );
  } else {
    return (
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  <MenuItem
                    key={page.key}
                    onClick={handleCloseNavMenu}
                    component={Link}
                    to={page.url}
                  >
                    <Typography textAlign="center">{page.item}</Typography>
                  </MenuItem>
                ))}
                <hr />
                {guestPages.map((page) => (
                  <MenuItem
                    key={page.key}
                    onClick={handleCloseNavMenu}
                    component={Link}
                    to={page.url}
                  >
                    <Typography textAlign="center">{page.item}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
            >
              Sarid Shirt
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page) => (
                <Button
                  key={page.key}
                  onClick={handleCloseNavMenu}
                  component={Link}
                  to={page.url}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  {page.item}
                </Button>
              ))}
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="ตะกร้าสินค้า">
                <IconButton aria-label="cart" component={Link} to="/cart">
                  <StyledBadge badgeContent={total} color="secondary">
                    <ShoppingCartIcon />
                  </StyledBadge>
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
              {guestPages.map((page) => (
                <Button
                  key={page.key}
                  onClick={handleCloseNavMenu}
                  component={Link}
                  to={page.url}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  {page.item}
                </Button>
              ))}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    );
  }
};

export default ResponsiveAppBar;
