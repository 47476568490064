import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import qrImage from '../assets/QRCode.JPG';

const Contact = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Box
        sx={{
          bgcolor: 'background.paper',
          pt: 8,
          pb: 6,
        }}
      >
        <Container maxWidth="sm" align="center">
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
          >
            Sarid Shirt
          </Typography>
          <Typography
            variant="h5"
            align="center"
            color="text.secondary"
            paragraph
          >
            ร้านตั้งอยู่ที่ตลาดนัดจตุจักร
          </Typography>
          <Typography
            variant="h5"
            align="center"
            color="text.secondary"
            paragraph
          >
            โครงการ 18 ซอย 26/4 ห้อง 090
          </Typography>
          {/* <div className="sarid-container"> */}
          <Grid container justifyContent="center">
            <Grid item>
              <Typography
                variant="h5"
                align="center"
                color="text.secondary"
                paragraph
              >
                โทร:
              </Typography>
            </Grid>
            <Grid item sx={{ marginLeft: 2 }}>
              <Typography variant="h5" align="center" color="Blue" paragraph>
                084-3583785
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <div className="line-btn">
              <span className="icon"></span>
              <button className="line-button-contact" onClick={handleClickOpen}>
                <Typography style={{ color: 'white' }}>ไลน์ร้านค้า</Typography>
              </button>
            </div>
          </Grid>
        </Container>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        component="form"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>QR code ร้านค้า</DialogTitle>
        <DialogContent>
          <div className="sarid-container">
            <div className="line-qr">
              <img src={qrImage} alt=""></img>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>ปิด</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Contact;
