import React, { useState, Component, useEffect, useRef } from 'react';
// import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { styled } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {
  Alert,
  Button,
  Input,
  IconButton,
  TextField,
  Box,
  Grid,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableHead,
  TableBody,
  TableRow,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import CategoryServices from '../../services/category.service';
import ProductServices from '../../services/product.service';
import AuthService from '../../services/auth.service';

const AddProduct = () => {
  const [categoriesList, setCategoriesList] = useState([]);
  const [warning, setWarning] = useState(false);
  const [coverFile, setCoverFile] = useState([]);
  const [photoFiles, setPhotoFiles] = useState([]);
  const [category, setCategory] = useState('1');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [coverPhoto, setCoverPhoto] = useState();
  const [color, setColor] = useState('');
  const [colorData, setColorData] = useState([]);
  const [productName, setProductName] = useState('');
  const [sizeValue, setSizeValue] = useState('mlxl');
  const [err, setErr] = useState('');
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const formRef = useRef();

  const data1 = [
    {
      size: 'M',
      chest: '38',
      price: '0',
    },
    {
      size: 'L',
      chest: '40',
      price: '0',
    },
  ];
  const data2 = [
    {
      size: 'M',
      chest: '38',
      price: '0',
    },
    {
      size: 'L',
      chest: '40',
      price: '0',
    },
    {
      size: 'XL',
      chest: '42',
      price: '0',
    },
  ];
  const data3 = [
    {
      size: 'F',
      chest: '44',
      price: '0',
    },
  ];
	const data4 = [
    {
      size: 'XL',
      chest: '42',
      price: '0',
    },
    {
      size: 'XXL',
      chest: '44',
      price: '0',
    },
  ];
	const data5 = [
    {
      size: 'M',
      chest: '38',
      price: '0',
    },
    {
      size: 'L',
      chest: '40',
      price: '0',
    },
    {
      size: 'XL',
      chest: '42',
      price: '0',
    },
    {
      size: 'XXL',
      chest: '44',
      price: '0',
    },
  ];
	const data6 = [
    {
      size: 'S',
      chest: '36',
      price: '0',
    },
    {
      size: 'M',
      chest: '38',
      price: '0',
    },
    {
      size: 'L',
      chest: '40',
      price: '0',
    },
    {
      size: 'XL',
      chest: '42',
      price: '0',
    },
  ];
	const data7 = [
    {
      size: 'S',
      chest: '36',
      price: '0',
    },
    {
      size: 'M',
      chest: '38',
      price: '0',
    },
    {
      size: 'L',
      chest: '40',
      price: '0',
    },
    {
      size: 'XL',
      chest: '42',
      price: '0',
    },
		{
      size: 'XXL',
      chest: '44',
      price: '0',
    },
  ];
  const [sizeChartData, setSizeChartData] = useState([
    {
      size: 'M',
      chest: '38',
      price: '0',
    },
    {
      size: 'L',
      chest: '40',
      price: '0',
    },
    {
      size: 'XL',
      chest: '42',
      price: '0',
    },
  ]);
  useEffect(() => {
    const adminAuth = async () => {
      await AuthService.isAdmin();
    };
    const getCategories = async () => {
      await CategoryServices.getAllCategories().then((response) => {
        setCategoriesList(response);
      });
    };
    adminAuth();
    getCategories();
  }, []);
  const changeColor = (event) => {
    setColor(event.target.value);
  };

  const transferValue = (event) => {
    event.preventDefault();
    if (color !== '') {
      addRows(color);
      clearState();
    } else {
      alert('กรุณาพิมพ์สีที่ต้องการเพิ่ม');
    }
    clearState();
  };
  const clearState = () => {
    setColor('');
  };

  const addRows = (data) => {
    const updaterColorData = [...colorData];
    updaterColorData.push(data);
    setColorData(updaterColorData);
  };
  const deleteColor = (e) => {
    const s = colorData.filter((color) => color !== e);
    setColorData(s);
  };
  const colorRows = colorData.map((info, i) => {
    return (
      <TableRow key={i}>
        <TableCell component="th" scope="row">
          {info}
        </TableCell>
        <TableCell align="center">
          <IconButton onClick={() => deleteColor(info)}>
            <span className="delete-color">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
            </span>
          </IconButton>
        </TableCell>
      </TableRow>
    );
  });
  const createStock = async (id) => {
    await ProductServices.createProductStock(id);
  };

  const handleChangeCategory = (e) => {
    setCategory(e.target.value);
  };
  const handleChangeCover = (e) => {
    const filesArray = Array.from(e.target.files).map((file) =>
      URL.createObjectURL(file)
    );
    setCoverPhoto(filesArray[0]);
    setCoverFile(e.target.files);
  };
  const handleChangeSize = (event) => {
    if (event.target.value === 'ml') {
      setSizeChartData(data1);
    } else if (event.target.value === 'mlxl') {
      setSizeChartData(data2);
    } else if (event.target.value === 'xlxxl') {
      setSizeChartData(data4);
    } else if (event.target.value === 'mlxlxxl') {
      setSizeChartData(data5);
    } else if (event.target.value === 'smlxl') {
      setSizeChartData(data6);
    } else if (event.target.value === 'smlxlxxl') {
      setSizeChartData(data7);
    }else {
      setSizeChartData(data3);
    }
    setSizeValue(event.target.value);
  };
  const handleChestUpdate = (size, chest) => {
    const newData = sizeChartData.map((row) => {
      if (row.size === size) {
        return {
          ...row,
          chest,
        };
      }
      return row;
    });
    setSizeChartData(newData);
  };
  const handleImageChange = (e) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files).map((file) =>
        URL.createObjectURL(file)
      );
      const files = Array.from(e.target.files);
      setPhotoFiles((prev) => prev.concat(files));

      setSelectedFiles((prevImages) => prevImages.concat(filesArray));
      Array.from(e.target.files).map((file) => URL.revokeObjectURL(file));
    }
  };
  const handlePriceUpdate = (size, price) => {
    const newData = sizeChartData.map((row) => {
      if (row.size === size) {
        return {
          ...row,
          price,
        };
      }
      return row;
    });
    setSizeChartData(newData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (coverFile.length === 0) {
      setErr('กรุณาใส่รูปปกสินค้า');
      setWarning(true);
    } else if (colorData.length < 1) {
      setErr('กรุณาใส่สีของสินค้า');
      setWarning(true);
    } else {
      setErr('');
      setWarning(false);
      setOpenBackdrop(true);

      const cover = await uploadToCloudinary(coverFile);

      if (cover.length > 0) {
        const coverUrl = cover[0].url;
        try {
          const productData = {
            name: productName,
            category_id: category,
          };
          const productRes = await ProductServices.createProduct(productData);
          if (productRes) {
            const id = productRes.id;
            const imgData = {
              image: coverUrl,
              product_id: id,
              cover_image: true,
            };
            await ProductServices.uploadPictures(imgData);

            for (let c = 0; c < colorData.length; c++) {
              const data = {
                color_name: colorData[c],
                product_id: id,
              };
              await ProductServices.createColor(data);
            }
            for (let s = 0; s < sizeChartData.length; s++) {
              const sizeData = {
                size_name: sizeChartData[s].size,
                chest: sizeChartData[s].chest,
                price: sizeChartData[s].price,
                product_id: id,
              };
              await ProductServices.createSize(sizeData);
            }
            if (photoFiles.length > 0) {
              const productPhoto = await uploadToCloudinary(photoFiles);
              for (let i = 0; i < productPhoto.length; i++) {
                const imageUrl = productPhoto[i].url;
                const imgData = {
                  image: imageUrl,
                  product_id: id,
                  cover_image: false,
                };
                await ProductServices.uploadPictures(imgData);
              }
            }
            createStock(id);
            setOpenBackdrop(false);
            window.location = `/addproduct/addStock/${id}`;
          }
        } catch (error) {}
      }
    }
  };
  const renderCover = (coverPhoto) => {
    if (coverPhoto) {
      return <img src={coverPhoto} alt="" />;
    } else {
      return <FontAwesomeIcon icon={faImage} color="#3B5998" size="5x" />;
    }
  };
  const renderPhotos = (source) => {
    return source.map((photo, index) => {
      return (
        <div className="imageHolder" key={index}>
          <img src={photo} alt="" />
          <span className="delete">
            <IconButton
              aria-label="delete"
              onClick={() => removeSelectImage(photo, index)}
            >
              <span className="delete-color">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              </span>
            </IconButton>
          </span>
        </div>
      );
    });
  };
  const removeSelectImage = (e, index) => {
    const s = selectedFiles.filter((photo) => photo !== e);
    setSelectedFiles(s);
    photoFiles.splice(index, 1);
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  const uploadToCloudinary = async (photo) => {
    const files = Array.from(photo);
    const formData = new FormData();

    files.forEach((file, i) => {
      formData.append(i, file);
    });
    try {
      const response = await ProductServices.uploadToCloud(formData);
      return response;
    } catch (error) {
      console.error(error);
    }
  };
  const errMessage = () => {
    if (warning) {
      return <Alert severity="error">{err}</Alert>;
    } else {
      return;
    }
  };

  return (
    <div>
      <div className="upload-container">
        <div>
          <h1>เพิ่มสินค้าใหม่</h1>
        </div>
        <div className="upload">
          <div className="cover-container">
            <div className="upload-cover-form">{renderCover(coverPhoto)}</div>
            <div className="upload-cover-button">
              <label htmlFor="cover-photo">
                <Input
                  accept="image/*"
                  id="cover-photo"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={handleChangeCover}
                />
                <Button variant="contained" component="span">
                  อัพโหลดรูปปกสินค้า
                </Button>
              </label>
            </div>
          </div>
          <div className="upload-photo-button">
            <label htmlFor="product-photo">
              <input
                type="file"
                id="product-photo"
                multiple
                onChange={handleImageChange}
                accept="image/*"
                style={{ display: 'none' }}
              />
              <Button variant="contained" component="span">
                อัพโหลดรูปสินค้า
              </Button>
            </label>
          </div>
          <div className="imgsPreview">{renderPhotos(selectedFiles)}</div>
        </div>
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
            ref={formRef}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="product-name"
                  label="ชื่อสินค้า"
                  type="text"
                  name="product-name"
                  onChange={(event) => setProductName(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <label className="product-select">เลือกประเภท </label>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <Select value={category} onChange={handleChangeCategory}>
                    {categoriesList.map((val, key) => {
                      return (
                        <MenuItem value={val.category_id} key={key}>
                          {val.category_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <label className="product-select">เลือกขนาด </label>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <Select value={sizeValue} onChange={handleChangeSize}>
                    <MenuItem value="ml">M/L</MenuItem>
                    <MenuItem value="mlxl">M/L/XL</MenuItem>
 					<MenuItem value="xlxxl">XL/XXL</MenuItem>
					<MenuItem value="mlxlxxl">M/L/XL/XXL</MenuItem>
					<MenuItem value="smlxl">S/M/L/XL</MenuItem>
					<MenuItem value="smlxlxxl">S/M/L/XL/XXL</MenuItem>
                    <MenuItem value="f">F</MenuItem>
                  </Select>
                </FormControl>
                <Table sx={{ minWidth: 250 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Size</TableCell>
                      <TableCell align="center">รอบอก</TableCell>
                      <TableCell align="center">ราคา</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sizeChartData.map((row) => (
                      <SizeRow
                        key={row.size}
                        data={row}
                        onChestUpdate={handleChestUpdate}
                        onPriceUpdate={handlePriceUpdate}
                      />
                    ))}
                  </TableBody>
                </Table>
              </Grid>
              <Grid item xs={12}>
                <Table sx={{ minWidth: 250 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>สี</StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{colorRows}</TableBody>
                </Table>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="input-color"
                  label="สี"
                  type="text"
                  id="input-color"
                  value={color}
                  onChange={changeColor}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  className="add-color-bt"
                  variant="outlined"
                  onClick={transferValue}
                >
                  เพิ่มสี
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {errMessage()}
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 6 }}
              onClick={() => formRef.current.reportValidity()}
            >
              เพิ่มสินค้า
            </Button>
          </Box>
        </Box>
      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

class SizeRow extends Component {
  handleChange = (e) => {
    this.props.onChestUpdate(this.props.data.size, e.target.value);
  };
  handleChangePrice = (e) => {
    this.props.onPriceUpdate(this.props.data.size, e.target.value);
  };

  render() {
    const {
      data: { size, chest, price },
    } = this.props;

    return (
      <TableRow>
        <TableCell component="th" scope="row">
          {size}
        </TableCell>
        <TableCell align="center">
          <TextField value={chest} type="number" onChange={this.handleChange} />
        </TableCell>
        <TableCell align="center">
          <TextField
            type="number"
            value={price}
            onChange={this.handleChangePrice}
          />
        </TableCell>
      </TableRow>
    );
  }
}
export default AddProduct;
