import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TextField,
} from '@mui/material';
import { useParams } from 'react-router';
import ProductServices from '../services/product.service';
import StockService from '../services/stock.service';
import { blockInvalidNumber } from './BlockInvalidNumber';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditProductStock = ({ product_name, onRefresh }) => {
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);
  const [productSize, setProductSize] = useState([]);
  const [productColor, setProductColor] = useState([]);
  const [productStock, setProductStock] = useState([]);

  const updateStock = async () => {
    try {
      for (let i = 0; i < productStock.length; i++) {
        const stockID = productStock[i].stock_id;
        const data = {
          quantity: productStock[i].quantity,
        };
        await StockService.updateStockData(stockID, data);
      }
      onRefresh();
      setOpen(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleClickOpen = async () => {
    await ProductServices.getProductSize(id).then((response) => {
      setProductSize(response);
    });
    await ProductServices.getProductStock(id).then((response) => {
      setProductStock(response);
    });
    await ProductServices.getProductColor(id).then((response) => {
      setProductColor(response);
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleStockUpdate = (e, stock_id) => {
    const quantity = e.target.value;
    const newData = productStock.map((row) => {
      if (row.stock_id === stock_id) {
        return {
          ...row,
          quantity,
        };
      }
      return row;
    });
    setProductStock(newData);
  };
  const handleSortStock = (colorId) => {
    const filter = productStock.filter((item) => item.color_id === colorId);
    const sortArr = filter.sort((a, b) => a.size_id - b.size_id);
    return sortArr;
  };

  return (
    <div className="space-bt">
      <Button fullWidth variant="contained" onClick={handleClickOpen}>
        แก้ไขคลังสินค้า
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              แก้ไขคลังสินค้า {product_name}
            </Typography>
            <Button autoFocus color="inherit" onClick={updateStock}>
              บันทึก
            </Button>
          </Toolbar>
        </AppBar>
        <List>
          <div className="sarid-container">
            <Table sx={{ minWidth: 400 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>สี</TableCell>
                  {productSize.map((column) => (
                    <TableCell key={column.size_id}>
                      {column.size_name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {productColor.map((row) => (
                  <TableRow key={row.color_id}>
                    <TableCell>{row.color_name}</TableCell>
                    {handleSortStock(row.color_id).map((size) => (
                      <TableCell key={size.stock_id}>
                        <TextField
                          value={size.quantity}
                          type="number"
                          onKeyDown={blockInvalidNumber}
                          onChange={(e) => handleStockUpdate(e, size.stock_id)}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </List>
      </Dialog>
    </div>
  );
};
export default EditProductStock;
