import {
  Button,
  Typography,
  Card,
  CardContent,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { Link } from 'react-router-dom';

import React, { useEffect, useState } from 'react';
import OrderService from '../../../services/order.service';
import Grid from '@mui/material/Grid';
import AuthService from '../../../services/auth.service';
import WarningCancelDialog from '../../../components/WarningCancelDialog';
import StockService from '../../../services/stock.service';
import Moment from 'moment';

const Orders = () => {
  const [allOrders, setAllOrders] = useState([]);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  useEffect(() => {
    const adminAuth = async () => {
      await AuthService.isAdmin();
    };
    const getOrders = async () => {
      const orders = await OrderService.getAllOrders();
      setAllOrders(orders);
    };
    adminAuth();
    getOrders();
  }, []);

  const cancelOrder = async (orderID) => {
    try {
      setOpenBackdrop(true);
      const response = await OrderService.getOrderById(orderID);
      const orderDetails = JSON.parse(response.order_details);
      for (let i = 0; i < orderDetails.length; i++) {
        const items = orderDetails[i].items;
        const filter = items.filter((s) => s.quantity !== 0);
        for (let j = 0; j < filter.length; j++) {
          const stockID = filter[j].stock_id;
          const data = {
            quantity: filter[j].quantity,
          };
          await StockService.incrementStock(stockID, data);
        }
      }
      await OrderService.deleteOrder(orderID);
      const orders = await OrderService.getAllOrders();
      setAllOrders(orders);
      setOpenBackdrop(false);
    } catch (err) {
      console.log(err);
    }
  };
  const checkOrders = () => {
    if (allOrders.length > 0) {
      return (
        <div className="sarid-container">
          {allOrders.map((order) => (
            <div className="information-container" key={order.order_id}>
              <Grid item xs container spacing={2}>
                <Grid item xs>
                  <Typography>คำสั่งซื้อเลขที่: {order.order_id}</Typography>
                </Grid>
                <WarningCancelDialog
                  title="ยกเลิกออเดอร์"
                  message="ต้องการยกเลิกออเดอร์ที่"
                  data={order.order_id}
                  button="ยืนยัน"
                  onWarningSubmit={() => cancelOrder(order.order_id)}
                ></WarningCancelDialog>
              </Grid>
              <Grid>
                <Typography>จากคุณ: {order.username}</Typography>
              </Grid>
              <Grid container>
                <Grid item sx={{ marginRight: 1 }}>
                  <Typography>สถานะ:</Typography>
                </Grid>
                <Grid item>
                  <Typography style={{ color: 'orange' }}>
                    {order.order_status}...
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item sx={{ marginRight: 1 }}>
                  <Typography>สั่งซื้อวันที่:</Typography>
                </Grid>
                <Grid item>
                  <Typography style={{ color: 'green' }}>
                    {Moment(order.created_at).format('DD/MM/YYYY HH:mm:ss')}
                    {/* {order.created_at} */}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs container spacing={2}>
                <Grid item xs></Grid>
                <Button
                  component={Link}
                  to={{
                    pathname: `/admin/orders/${order.order_id}`,
                    query: { id: order.order_id },
                  }}
                >
                  รายละเอียด
                </Button>
              </Grid>
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <div className="sarid-container">
          <Card sx={{ minWidth: 300 }}>
            <CardContent sx={{ justifyContent: 'center' }}>
              <Typography style={{ color: 'blue' }}>
                ไม่มีคำสั่งซื้อคงค้าง
              </Typography>
            </CardContent>
          </Card>
        </div>
      );
    }
  };
  return (
    <div>
      <div className="sarid-container">
        <h1>Order</h1>
      </div>

      {checkOrders()}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Orders;
