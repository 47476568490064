import React, { Component } from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from '@mui/material';

class Size extends Component {
  render() {
    const { data } = this.props;
    return (
	<div className="sarid-container">
      <Table sx={{ minWidth: 250 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Size</TableCell>
            <TableCell align="center">รอบอก</TableCell>
            <TableCell align="center">ราคา</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.size_name}>
              <TableCell component="th" scope="row">
                {row.size_name}
              </TableCell>
              <TableCell align="center">{row.chest}</TableCell>
              <TableCell align="center">{row.price}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
	  </div>
    );
  }
}
export default Size;
