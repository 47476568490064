import http from '../http-common';

class SalesReportService {
  getReport = async (dayReq) => {
    try {
      const response = await http.post(`/sales-report`, dayReq);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };
  getReportByDay = async (day) => {
    try {
      console.log('from service:', day);
      const response = await http.get(`/sales-report/day/${day}`);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };
  getReportByMonth = async (data) => {
    try {
      const response = await http.post(`/sales-report/month`, data);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };
  getOrderReport = async (data) => {
    try {
      const response = await http.post(`/sales-report/orders`, data);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };
}

export default new SalesReportService();
