import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import React, { useEffect, useState } from 'react';
import OrderService from '../../../../services/order.service';
import Grid from '@mui/material/Grid';
import Moment from 'moment';
import AuthService from '../../../../services/auth.service';

const OrderHistory = () => {
  const [allOrders, setAllOrders] = useState([]);
  useEffect(() => {
    const adminAuth = async () => {
      await AuthService.isAdmin();
    };
    const getOrders = async () => {
      const orders = await OrderService.getAllComplete();
      setAllOrders(orders);
      console.log(orders);
    };
    adminAuth();
    getOrders();
  }, []);
  return (
    <div className="sarid-container">
      <h1>รายการสั่งซื้อทั้งหมด</h1>
      {allOrders.map((order) => (
        <div className="information-container" key={order.order_id}>
          <Grid>
            <Typography>คำสั่งซื้อเลขที่: {order.order_id}</Typography>
          </Grid>
          <Grid>
            <Typography>จากคุณ: {order.username}</Typography>
          </Grid>
          <Grid container>
            <Grid item sx={{ marginRight: 1 }}>
              <Typography>สั่งซื้อวันที่:</Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: 'green' }}>
                {Moment(order.created_at).format('DD/MM/YYYY HH:mm:ss')}
                {/* {order.created_at} */}
              </Typography>
            </Grid>
          </Grid>

          <Grid item container spacing={2}>
            <Grid item xs></Grid>
            <Button
              component={Link}
              to={{
                pathname: `/admin/orders/history/${order.order_id}`,
                query: { id: order.order_id },
              }}
            >
              รายละเอียด
            </Button>
          </Grid>
        </div>
      ))}
    </div>
  );
};

export default OrderHistory;
