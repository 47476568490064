import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import {
  Grid,
  List,
  ListItem,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Size from '../../../components/Size';
import EditSizeDialog from '../../../components/EditSizeDialog';
import AddColor from '../../../components/AddColorDialog';
import EditColor from '../../../components/EditColorDialog';
import EditProductInfo from '../../../components/EditProductInfo';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import WarningDialog from '../../../components/WarningDialog';
import EditProductStock from '../../../components/EditStockDialog';
import ProductServices from '../../../services/product.service';
import EditPhotoDialog from '../../../components/EditPhotoDialog';
import ChangeCoverDialog from '../../../components/ChangeCoverDialog';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import AuthService from '../../../services/auth.service';
import AddSizeDialog from '../../../components/AddSizeDialog';

const fadeProperties = {
  infinite: false,
  indicators: true,
  autoplay: false,
};

const EditProduct = () => {
  const { id } = useParams();
  const [productInfo, setProductInfo] = useState([]);
  const [allPhotos, setAllPhotos] = useState([]);
  const [productSize, setProductSize] = useState([]);
  const [productColor, setProductColor] = useState([]);
  const [productStock, setProductStock] = useState([]);
  const [openWarning, setOpenWarning] = useState(false);

  useEffect(() => {
    const adminAuth = async () => {
      await AuthService.isAdmin();
    };
    const getProSize = async () => {
      await ProductServices.getProductSize(id).then((response) => {
        setProductSize(response);
      });
    };

    const getProStock = async () => {
      await ProductServices.getProductStock(id).then((response) => {
        setProductStock(response);
      });
    };
    const getProColor = async () => {
      await ProductServices.getProductColor(id).then((response) => {
        setProductColor(response);
      });
    };
    const getProInfo = async () => {
      await ProductServices.getProductInfo(id).then((response) => {
        setProductInfo(response);
      });
    };
    const getAllPhoto = async () => {
      await ProductServices.getAllProductPhotos(id).then((response) => {
        setAllPhotos(response);
      });
    };
    adminAuth();
    getProInfo();
    getProSize();
    getProColor();
    getProStock();
    getAllPhoto();
  }, [id]);

  const handleCloseWarning = () => {
    setOpenWarning(false);
  };

  const handleOpenWarning = () => {
    setOpenWarning(true);
  };

  const updateColor = async () => {
    await ProductServices.getProductColor(id).then((response) => {
      setProductColor(response);
    });
  };
  const updateInfo = async () => {
    await ProductServices.getProductInfo(id).then((response) => {
      setProductInfo(response);
    });
  };
  const updateSize = async () => {
    await ProductServices.getProductSize(id).then((response) => {
      setProductSize(response);
    });
  };
  const updateStock = async () => {
    await ProductServices.getProductStock(id).then((response) => {
      setProductStock(response);
    });
  };

  const deleteColor = async (color_id) => {
    try {
      await ProductServices.deleteProductColor(color_id);
      const response = await ProductServices.getStockByColor(color_id);

      if (response.length > 0) {
        const stock = response;
        for (let i = 0; i < stock.length; i++) {
          const stockID = stock[i].stock_id;
          await ProductServices.deleteStock(stockID);
        }
      }
      refreshColorAndStock();
    } catch (err) {
      console.log(err);
    }
  };

  const deleteProduct = async () => {
    const productID = productInfo.product_id;
    try {
      await ProductServices.deleteProduct(productID);
      window.location = '/admin';
    } catch (err) {
      console.log(err);
    }
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  const refreshColor = () => {
    updateColor();
  };

  const refreshPhoto = async () => {
    await ProductServices.getAllProductPhotos(id).then((response) => {
      setAllPhotos(response);
    });
    // updateInfo();
  };
  const refreshProductInfo = () => {
    updateInfo();
  };
  const refreshSize = () => {
    updateSize();
  };
  const refreshSizeStock = () => {
    updateSize();
    updateStock();
  };
  const refreshColorAndStock = () => {
    updateColor();
    updateStock();
  };

  const refreshStock = () => {
    updateStock();
  };
  const handleSortStock = (colorId) => {
    const filter = productStock.filter((item) => item.color_id === colorId);
    const sortArr = filter.sort((a, b) => a.size_id - b.size_id);
    return sortArr;
  };

  const colorRows = productColor.map((color) => {
    return (
      <TableRow key={color.color_id}>
        <TableCell component="th" scope="row">
          {color.color_name}
        </TableCell>
        <TableCell align="center">
          <Grid container spacing={1}>
            <EditColor
              color={color.color_name}
              color_id={color.color_id}
              onRefresh={refreshColor}
            />
            <WarningDialog
              title="คุณแน่ใจว่าต้องการลบสีนี้ ?"
              message="ต้องการลบสี"
              data={color.color_name}
              button="ลบ"
              onWarningSubmit={() => deleteColor(color.color_id)}
            />
          </Grid>
        </TableCell>
      </TableRow>
    );
  });

  return (
    <div className="sarid-container">
      <Grid container spacing={1}>
        <Grid item md={6} xs={12}>
          <div className="slide-container">
            <Fade {...fadeProperties}>
              {allPhotos.map((slideImage, index) => (
                <div className="each-slide" key={index}>
                  <div
                    style={{ backgroundImage: `url(${slideImage.image_url})` }}
                  ></div>
                </div>
              ))}
            </Fade>
          </div>
        </Grid>
        <Grid item md={6} xs={12}>
          <List>
            <ListItem>ชื่อสินค้า: {productInfo.name}</ListItem>
            <ListItem>ประเภท: {productInfo.category_name}</ListItem>
            <ListItem>
              <EditProductInfo
                name={productInfo.name}
                catID={productInfo.category_id}
                onRefresh={refreshProductInfo}
              />
              <EditPhotoDialog
                info={productInfo}
                photos={allPhotos}
                onRefresh={refreshPhoto}
              />
            </ListItem>

            <ListItem>
              <Size data={productSize} />
            </ListItem>
            <ListItem>
              <EditSizeDialog onRefresh={refreshSize} />
			  {productSize.length > 0 && (
                <AddSizeDialog
                  size={productSize}
                  onRefresh={refreshSizeStock}
                />
              )}
            </ListItem>
          </List>
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{ mt: 3 }}>
        <Grid item md={6} xs={12}>
          <ChangeCoverDialog
            info={productInfo}
            photos={allPhotos}
            onRefresh={refreshPhoto}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{ mt: 3 }}>
        <Grid item md={6} xs={12}>
          <Table sx={{ minWidth: 250 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>สี</StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>{colorRows}</TableBody>
          </Table>
          <Grid sx={{ marginTop: 2 }}>
            <AddColor
              productName={productInfo.name}
              onRefresh={refreshColorAndStock}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={1} sx={{ mt: 3 }}>
        <Grid item md={6} xs={12}>
          <Table sx={{ minWidth: 250 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>สี</StyledTableCell>
                {productSize.map((column) => (
                  <StyledTableCell key={column.size_id}>
                    {column.size_name}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {productColor.map((row) => (
                <TableRow key={row.color_id}>
                  <TableCell>{row.color_name}</TableCell>
                  {handleSortStock(row.color_id).map((size) => (
                    <TableCell key={size.stock_id}>{size.quantity}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Grid sx={{ marginTop: 2 }}>
            <EditProductStock
              product_name={productInfo.name}
              onRefresh={refreshStock}
            />
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={openWarning}
        onClose={handleCloseWarning}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">ต้องการลบสินค้า</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            คุณแน่ใจว่าต้องการลบสินค้านี้ออกจากระบบ ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWarning} autoFocus>
            ยกเลิก
          </Button>
          <Button onClick={deleteProduct}>ลบ</Button>
        </DialogActions>
      </Dialog>
      <Button
        fullWidth
        variant="outlined"
        color="warning"
        sx={{ marginBottom: 10 }}
        onClick={handleOpenWarning}
      >
        ลบสินค้า
      </Button>
    </div>
  );
};
export default EditProduct;
