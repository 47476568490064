import React, { useEffect, useState } from 'react';
import AuthService from '../../../services/auth.service';
import UserService from '../../../services/user.service';
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const eye = <FontAwesomeIcon icon={faEye} />;
const eyeClose = <FontAwesomeIcon icon={faEyeSlash} />;

const AdminAccount = () => {
  const [password, setPassWord] = React.useState('');
  const [confirmPass, setConfirmPass] = React.useState('');
  const [errMsg, setErrMsg] = useState('');
  const [passwordShown, setPasswordShown] = React.useState(false);
  const [confirmPassShown, setConfirmPassShown] = React.useState(false);

  useEffect(() => {
    const validateUser = async () => {
      const currentUser = await AuthService.getCurrentUser();
      if (currentUser !== false && currentUser.role === 'admin') {
      } else {
        window.location = '/';
      }
    };
    validateUser();
  }, []);

  const enableButton = () => {
    if (password.length > 0 && confirmPass.length > 0) {
      return false;
    } else {
      return true;
    }
  };
  const handleChangePassword = async (e) => {
    e.preventDefault();
    if (password !== confirmPass) {
      setErrMsg('รหัสผ่านไม่ตรงกัน');
    } else {
      setErrMsg('');
      const data = {
        password: password,
      };
      const currentUser = await AuthService.getCurrentUser();
      try {
        await UserService.updatePassword(currentUser.user_id, data);
        AuthService.logout();
      } catch (err) {
        console.log(err);
      }
    }
  };
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const toggleConfirmPassVisiblity = () => {
    setConfirmPassShown(confirmPassShown ? false : true);
  };

  return (
    <div className="sarid-container">
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            ตั้งค่ารหัสผ่านใหม่
          </Typography>
          <Box component="form" noValidate sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="pass-wrapper">
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label="รหัสผ่าน"
                    type={passwordShown ? 'text' : 'password'}
                    id="password"
                    onChange={(event) => setPassWord(event.target.value)}
                  />
                  <i onClick={togglePasswordVisiblity}>
                    {passwordShown ? eyeClose : eye}
                  </i>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="pass-wrapper">
                  <TextField
                    required
                    fullWidth
                    name="confirm-password"
                    label="ยืนยันรหัสผ่าน"
                    type={confirmPassShown ? 'text' : 'password'}
                    id="confirm-password"
                    onChange={(event) => setConfirmPass(event.target.value)}
                  />
                  <i onClick={toggleConfirmPassVisiblity}>
                    {confirmPassShown ? eyeClose : eye}
                  </i>
                </div>
              </Grid>
            </Grid>
            <div>
              <span style={{ color: 'red' }}>{errMsg}</span>
            </div>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={enableButton()}
              onClick={handleChangePassword}
            >
              ตั้งค่ารหัสผ่านใหม่
            </Button>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default AdminAccount;
