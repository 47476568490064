import http from '../http-common';
class CategoryServices {
  getAllCategories = async () => {
    try {
      const response = await http.get('/categories');
      return response.data;
    } catch (err) {
      console.log(err);
      return null;
    }
  };
}

export default new CategoryServices();
