import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom';
import ProductServices from '../services/product.service';
import Box from '@mui/material/Box';
import ShopService from '../services/shop.service';

const Home = () => {
  const [products, setProducts] = useState([]);
  const [shopInfo, setShopInfo] = useState([]);
  useEffect(() => {
    const allProducts = async () => {
      const response = await ProductServices.getAllProducts();
      const productAvailable = response.filter(
        (product) => product.status === 'Available'
      );
      setProducts(productAvailable);
    };
    const getShopInfo = async () => {
      await ShopService.getInfo().then((response) => {
        setShopInfo(response);
      });
    };
    allProducts();
    getShopInfo();
  }, []);
  return (
    <div className="container">
      <Box
        sx={{
          bgcolor: 'background.paper',
          pt: 8,
          pb: 6,
        }}
      >
        <Container maxWidth="sm">
          
          <Typography
            variant="h5"
            align="center"
            color="text.secondary"
            paragraph
          >
            {shopInfo.info1}
          </Typography>
          <Typography
            variant="h5"
            align="center"
            color="text.secondary"
            paragraph
          >
            {shopInfo.info2}
          </Typography>
          <Typography variant="h5" align="center" color="red" paragraph>
            {shopInfo.info3}
          </Typography>
        </Container>
      </Box>
      <Container sx={{ py: 8 }} maxWidth="md">
        <Grid container spacing={4}>
          {products.map((card) => (
            <Grid
              item
              key={card.product_id}
              xs={12}
              sm={6}
              md={4}
              component={Link}
              to={{
                pathname: `product/${card.product_id}`,
                query: { id: card.product_id },
              }}
              style={{ textDecoration: 'none' }}
              underline="none"
            >
              <div className="product-card">
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <CardMedia
                    component="img"
                    image={card.image_url}
                    alt="random"
                  />
                  {card.isNew === 1 ? (
                    <Typography
                      className="product-card-name"
                      style={{ color: 'white' }}
                    >
                      New!!!
                    </Typography>
                  ) : null}

                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom component="h1">
                      {card.name}
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default Home;
