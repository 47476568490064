import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import BillService from '../../services/bill.service';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  Grid,
  Typography,
  TableContainer,
  Paper,
  Divider,
} from '@mui/material';

import TableCell from '@mui/material/TableCell';
import Moment from 'moment';
import OrderService from '../../services/order.service';

const InvoiceDetails = () => {
  const { id } = useParams();
  const [billDetails, setBillDetails] = useState([]);
  const [billItems, setBillItems] = useState([]);
  const [orderInfo, setOrderInfo] = useState([]);

  useEffect(() => {
    const getBill = async () => {
      const bill = await BillService.getBill(id);
      setBillDetails(bill);
      const convert = JSON.parse(bill.bill_details);
      const order = await OrderService.getOrderById(bill.order_id);
      setOrderInfo(order);
      setBillItems(convert);
    };
    getBill();
  }, [id]);

  function ccyFormat(num) {
    return Number(num).toFixed(2);
  }
  function subtotal(items) {
    const total = items
      .map(({ totalPrice }) => totalPrice)
      .reduce((sum, i) => sum + i, 0);
    return total;
  }
  const total = () => {
    const all = [];
    for (let i = 0; i < billItems.length; i++) {
      const stock = billItems[i].items;
      all.push(...stock);
    }
    const total = subtotal(all);
    return total;
  };

  return (
    <div className="sarid-container">
      <h1>ยอดบิล</h1>
      <h2>คำสั่งซื้อเลขที่: {billDetails.order_id}</h2>
      <Grid container sx={{ marginTop: 5 }}>
        <Grid>
          <Typography>คำสั่งซื้อจากคุณ: </Typography>
        </Grid>
        <Grid sx={{ marginLeft: 2 }}>
          <Typography style={{ color: 'blue' }}>
            {billDetails.username}
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ marginTop: 1 }}>
        <Grid>
          <Typography>ชื่อพนักงาน: </Typography>
        </Grid>
        <Grid sx={{ marginLeft: 2 }}>
          <Typography>{billDetails.author}</Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ marginTop: 1 }}>
        <Grid>
          <Typography>วันที่สั่งซื้อ: </Typography>
        </Grid>
        <Grid sx={{ marginLeft: 2 }}>
          <Typography>
            {Moment(orderInfo.created_at).format('DD/MM/YYYY HH:mm:ss')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ marginTop: 1 }}>
        <Grid>
          <Typography>วันที่ออกใบเสร็จ: </Typography>
        </Grid>
        <Grid sx={{ marginLeft: 2 }}>
          <Typography>
            {Moment(billDetails.created_at).format('DD/MM/YYYY HH:mm:ss')}
          </Typography>
        </Grid>
      </Grid>

      {billItems.map((product) => (
        <div className="cart-item" key={product.product_id}>
          <Grid item xs={12} sm container>
            <Grid item xs container spacing={2}>
              <Grid item sx={{ marginTop: 1, marginBottom: 1 }}>
                <Typography>{product.product_name}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 400 }} aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <TableCell>รายการ</TableCell>
                  <TableCell align="right">จำนวน</TableCell>
                  <TableCell align="right">ราคา/ต่อหน่วย</TableCell>
                  <TableCell align="right">จำนวนเงิน</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {product.items.map((row) => (
                  <TableRow key={row.stock_id}>
                    <TableCell>{row.description}</TableCell>
                    <TableCell align="right">{row.quantity}</TableCell>
                    <TableCell align="right">{row.price}</TableCell>
                    <TableCell align="right">
                      {/* {row.totalPrice} */}
                      {ccyFormat(row.totalPrice)}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell rowSpan={3} />
                  <TableCell colSpan={2}>รวมจำนวนเงิน</TableCell>
                  <TableCell align="right">
                    {ccyFormat(subtotal(product.items))}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ))}
      <Grid item xs={12} sm container>
        <Grid item xs container spacing={2}>
          <Grid item sx={{ marginTop: 1 }}>
            <Typography>รวมค่าสินค้า</Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Typography>{ccyFormat(total())}</Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid item xs={12} sm container>
        <Grid item xs container spacing={2}>
          <Grid item sx={{ marginTop: 1 }}>
            <Typography>ค่าส่ง</Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Typography>{ccyFormat(billDetails.shipping_fee)}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} sm container style={{ backgroundColor: '#9ACD32' }} sx={{mb:6}}>
        <Grid item xs container spacing={2}>
          <Grid item sx={{ marginTop: 1 }}>
            <Typography>ยอดบิลทั้งหมด</Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Typography>
            {ccyFormat(total() + billDetails.shipping_fee)}
          </Typography>
        </Grid>
      </Grid>
      <Divider />
    </div>
  );
};
export default InvoiceDetails;
