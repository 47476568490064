import React, { useEffect, useState } from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';
import { StyledTableCell } from '../../../components/StyledTableCell';
import StockService from '../../../services/stock.service';
import ProductServices from '../../../services/product.service';
import ColorService from '../../../services/color.service';

const Stock = () => {
  const [productData, setProductData] = useState([]);
  const [stockData, setStockData] = useState([]);
  const [sizeData, setSizeData] = useState([]);
  const [colorData, setColorData] = useState([]);
  useEffect(() => {
    const getStock = async () => {
      const data = await StockService.getAllStock();
      setStockData(data);
    };
    const getProductData = async () => {
      const response = await ProductServices.getAllProducts();
      console.log(response);
      setProductData(response);
    };
    const getSize = async () => {
      const size = await ProductServices.getAllProductSize();
      setSizeData(size);
    };
    const getColor = async () => {
      const colors = await ColorService.getAllColor();
      setColorData(colors);
    };
    getStock();
    getProductData();
    getSize();
    getColor();
  }, []);
  return (
    <div className="sarid-container">
      <h1>Stock</h1>
      {productData.map((o) => (
        <div className="cart-item" key={o.product_id}>
          <Grid item xs={12} sm container>
            <Grid item xs container spacing={2}>
              <Grid item sx={{ marginTop: 1, marginBottom: 1 }}>
                <Typography>{o.name}</Typography>
              </Grid>
            </Grid>
          </Grid>
		  <div className="table-container">
          <Table sx={{ minWidth: 250 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>สี</StyledTableCell>
                {sizeData
                  .filter((size) => size.product_id === o.product_id)
                  .map((column) => (
                    <StyledTableCell key={column.size_id}>
                      {column.size_name}
                    </StyledTableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {colorData
                .filter((color) => color.product_id === o.product_id)
                .map((row) => (
                  <TableRow key={row.color_id}>
                    <TableCell>{row.color_name}</TableCell>
                    {stockData
                      .filter((stock) => stock.color_id === row.color_id)
                      .map((size) => (
                        <TableCell key={size.stock_id}>
                          <Typography sx={{ marginTop: 1, marginRight: 1 }}>
                            {size.quantity}
                          </Typography>
                        </TableCell>
                      ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
		  </div>
        </div>
      ))}
    </div>
  );
};
export default Stock;
