import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, TextareaAutosize } from '@mui/material';
import Slide from '@mui/material/Slide';

import ShopService from '../services/shop.service';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditShopInfoDialog({ onRefresh }) {
  const [open, setOpen] = React.useState(false);
  const [info1, setInfo1] = useState([]);
  const [info2, setInfo2] = useState([]);
  const [info3, setInfo3] = useState([]);

  const handleClickOpen = async () => {
    await ShopService.getInfo().then((response) => {
      setInfo1(response.info1);
      setInfo2(response.info2);
      setInfo3(response.info3);
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChangeInfo1 = (e) => {
    setInfo1(e.target.value);
  };
  const handleChangeInfo2 = (e) => {
    setInfo2(e.target.value);
  };
  const handleChangeInfo3 = (e) => {
    setInfo3(e.target.value);
  };
  const updateInfo = async () => {
    const data = {
      info1: info1,
      info2: info2,
      info3: info3,
    };
    try {
      await ShopService.updateInfo(data);
      onRefresh();
      setOpen(false);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div>
      <Button fullWidth variant="contained" onClick={handleClickOpen}>
        แก้ไข
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              แก้ไขข้อมูลสินค้า
            </Typography>
          </Toolbar>
        </AppBar>
        <List>
          <div className="sarid-container">
            <Grid container justifyContent="center" sx={{ marginTop: 5 }}>
              <Grid sx={{ marginRight: 2 }}>
                <Typography>ข้อมูล1:</Typography>
              </Grid>

              <TextareaAutosize
                aria-label="ข้อมูล1"
                minRows={3}
                value={info1}
                placeholder="ข้อมูล1"
                onChange={handleChangeInfo1}
                style={{ width: 400 }}
              />
            </Grid>
            <Grid container justifyContent="center" sx={{ marginTop: 5 }}>
              <Grid sx={{ marginRight: 2 }}>
                <Typography>ข้อมูล2:</Typography>
              </Grid>

              <TextareaAutosize
                aria-label="ข้อมูล2"
                minRows={3}
                value={info2}
                placeholder="ข้อมูล2"
                onChange={handleChangeInfo2}
                style={{ width: 400 }}
              />
            </Grid>
            <Grid container justifyContent="center" sx={{ marginTop: 5 }}>
              <Grid sx={{ marginRight: 2 }}>
                <Typography style={{ color: 'red' }}>ข้อมูล3:</Typography>
              </Grid>

              <TextareaAutosize
                aria-label="ข้อมูล3"
                minRows={3}
                value={info3}
                placeholder="ข้อมูล3"
                onChange={handleChangeInfo3}
                style={{ width: 400 }}
              />
            </Grid>
            <Grid container sx={{ marginTop: 3, maxWidth: 500 }}>
              <Button fullWidth variant="contained" onClick={updateInfo}>
                บันทึก
              </Button>
            </Grid>
          </div>
        </List>
      </Dialog>
    </div>
  );
}
