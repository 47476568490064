import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from '@mui/material';

import Moment from 'moment';
import { styled } from '@mui/material/styles';
import SalesReportService from '../../services/salesReport.service';
import { StyledTableCell } from '../StyledTableCell';

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
}));
const WeeklyReport = ({ sizeData, colorData, stockData }) => {
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [dailyReport, setDailyReport] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [salesStock, setSalesStock] = useState([]);
  const [emptyReport, setEmptyReport] = useState(false);

  const checkEmptyReport = () => {
    if (emptyReport) {
      return (
        <div className="sarid-container">
          <Card sx={{ minWidth: 350 }}>
            <CardContent>
              <Typography>ไม่พบยอดขายในวันที่คุณเลือก</Typography>
            </CardContent>
          </Card>
        </div>
      );
    }
  };

  const getSaleReportByDay = async () => {
    try {
      setEmptyReport(false);
      const dayReq = {
        startDate: start,
        endDate: end,
      };
      const response = await SalesReportService.getReport(dayReq);
      const reportList = [];
      if (response.length > 0) {
        const products = [];
        var productStock = [];
        var users = [];
        var salesTotalPrice = 0;
        var salesTotalQuantity = 0;

        for (let i = 0; i < response.length; i++) {
          const bill = response[i];
          const billItems = JSON.parse(bill.bill_details);
          let billTotal = 0;
          let qTotal = 0;
          const user = {
            username: bill.username,
          };
          const filterUser = users.filter(
            (name) => name.username === bill.username
          );
          if (filterUser.length === 0) {
            users.push(user);
          }
          for (let j = 0; j < billItems.length; j++) {
            const product = billItems[j];
            const productID = product.product_id;
            const total = subtotal(product.items);
            const totalQ = sumQuantity(product.items);
            billTotal += total;
            qTotal += totalQ;
            if (products.length > 0) {
              const findProduct = products.filter(
                (p) => p.product_id === productID
              );

              if (findProduct.length > 0) {
                products.forEach((x) => {
                  if (x.product_id === product.product_id) {
                    for (let k = 0; k < product.items.length; k++) {
                      const proItem = product.items[k];
                      const filter = x.items.filter(
                        (stock) => stock.stock_id === proItem.stock_id
                      );
                      if (filter.length > 0) {
                        const newQuantity = Number(proItem.quantity);
                        const newData = x.items.map((row) => {
                          if (row.stock_id === proItem.stock_id) {
                            const quantity =
                              Number(row.quantity) + Number(newQuantity);
                            return {
                              ...row,
                              quantity,
                            };
                          }
                          return row;
                        });
                        x.items = newData;
                      } else {
                        x.items.push(proItem);
                      }
                    }
                  }
                });
              } else {
                products.push({ ...product });
                try {
                  const filterStock = stockData.filter(
                    (s) => s.product_id === product.product_id
                  );
                  for (var stp in filterStock) {
                    filterStock[stp].quantity = Number(0);
                    productStock.push({ ...filterStock[stp] });
                  }
                } catch (err) {
                  console.log(err);
                }
              }
            } else {
              products.push({ ...product });
              try {
                const filterStock = stockData.filter(
                  (s) => s.product_id === product.product_id
                );
                for (var st in filterStock) {
                  filterStock[st].quantity = Number(0);
                  productStock.push({ ...filterStock[st] });
                }
              } catch (err) {
                console.log(err);
              }
            }
          }
          salesTotalPrice += billTotal;
          salesTotalQuantity += qTotal;
        }
        const reportData = {
          totalSales: salesTotalPrice,
          totalQuantity: salesTotalQuantity,
          totalOrders: response.length,
          salesItems: products,
          users: users.length,
        };
        for (let s = 0; s < products.length; s++) {
          const items = products[s].items;
          for (let it = 0; it < items.length; it++) {
            const newData = productStock.map((row) => {
              if (row.stock_id === items[it].stock_id) {
                const quantity = Number(items[it].quantity);
                return {
                  ...row,
                  quantity,
                };
              }
              return row;
            });
            productStock = newData;
          }
        }

        reportList.push(reportData);
        setDailyReport(reportList);
        setAllProducts(products);
        setSalesStock(productStock);
      } else {
        setEmptyReport(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  function subtotal(items) {
    const total = items
      .map(({ totalPrice }) => totalPrice)
      .reduce((sum, i) => sum + i, 0);
    return total;
  }
  function sumPrice(items) {
    var sum = 0;
    for (let i = 0; i < items.length; i++) {
      const sizePrice = sizeData.filter((s) => s.size_id === items[i].size_id);
      const price = Number(items[i].quantity) * Number(sizePrice[0].price);
      sum += price;
    }

    return sum;
  }
  function sumTotalPrice(items) {
    var sum = 0;
    for (let i = 0; i < items.length; i++) {
      const sizePrice = sizeData.filter((s) => s.size_id === items[i].size_id);
      const price = Number(items[i].quantity) * Number(sizePrice[0].price);
      sum += price;
    }

    return sum;
  }
  function sumProductQuantity(items) {
    const productQ = items
      .map(({ quantity }) => quantity)
      .reduce((sum, i) => sum + i, 0);
    return productQ;
  }
  function sumQuantity(items) {
    const q = items
      .map(({ quantity }) => quantity)
      .reduce((sum, i) => sum + i, 0);
    return q;
  }
  return (
    <div className="sarid-container">
      <Grid container spacing={3}>
        <Grid item>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="วันที่"
              value={start}
              onChange={(newValue) => {
                setStart(Moment(newValue).format('YYYY-MM-DD'));
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item sx={{ marginTop: 2, marginLeft: 5, marginRight: 5 }}>
          <Typography>ถึง</Typography>
        </Grid>
        <Grid item>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="วันที่"
              value={end}
              onChange={(newValue) => {
                setEnd(Moment(newValue).format('YYYY-MM-DD'));
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item sx={{ marginTop: 1 }}>
          <Button
            variant="contained"
            disabled={start === null || end === null}
            onClick={getSaleReportByDay}
          >
            ดูยอดขาย
          </Button>
        </Grid>
      </Grid>

     
        {dailyReport.map((val, key) => {
          return (
          
              <Grid container spacing={4}  key={val.totalSales}
            sx={{ marginTop: 5 }}>
                <Grid item xs={12} sm={6} md={4}>
                  <RootStyle
                    sx={{ color: '#145A32', backgroundColor: '#73C6B6' }}
                  >
                    <Typography variant="h5">{val.totalSales.toLocaleString()}</Typography>
                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                      บาท
                    </Typography>
                  </RootStyle>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <RootStyle
                    sx={{ color: '#145A32', backgroundColor: '#85C1E9' }}
                  >
                    <Typography variant="h5">{val.totalQuantity.toLocaleString()}</Typography>
                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                      ตัว
                    </Typography>
                  </RootStyle>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <RootStyle
                    sx={{ color: '#145A32', backgroundColor: '#C39BD3 ' }}
                  >
                    <Typography variant="h5">{val.totalOrders}</Typography>
                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                      ออเดอร์
                    </Typography>
                  </RootStyle>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <RootStyle
                    sx={{ color: '#145A32', backgroundColor: '#D98880 ' }}
                  >
                    <Typography variant="h5">{val.users}</Typography>
                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                      ผู้ใช้
                    </Typography>
                  </RootStyle>
                </Grid>
              </Grid>
         
          );
        })}
        <br />
        {checkEmptyReport()}
        {allProducts.map((p) => {
          return (
            <div className="table-sales" key={p.product_id}>
              <Typography>{p.product_name}</Typography>
              <Table sx={{ minWidth: 250, border: 0.5, borderRadius: 1 }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>สี</StyledTableCell>
                    {sizeData
                      .filter((size) => size.product_id === p.product_id)
                      .map((column) => (
                        <StyledTableCell key={column.size_id}>
                          {column.size_name}
                        </StyledTableCell>
                      ))}
                    <StyledTableCell>รวมเป็นเงิน</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {colorData
                    .filter((color) => color.product_id === p.product_id)
                    .map((row) => (
                      <TableRow key={row.color_id}>
                        <TableCell>{row.color_name}</TableCell>
                        {salesStock
                          .filter((stock) => stock.color_id === row.color_id)
                          .map((size) => (
                            <TableCell key={size.stock_id}>
                              <Typography sx={{ marginTop: 1, marginRight: 1 }}>
                                {size.quantity}
                              </Typography>
                            </TableCell>
                          ))}
                        <TableCell>
                          <Typography>
                            {sumPrice(
                              salesStock.filter(
                                (stock) => stock.color_id === row.color_id
                              )
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  <TableRow sx={{ backgroundColor: '#F7DC6F' }}>
                    <TableCell>
                      <Typography>รวม</Typography>
                    </TableCell>
                    {sizeData
                      .filter((size) => size.product_id === p.product_id)
                      .map((column) => (
                        <TableCell key={column.size_id}>
                          {sumProductQuantity(
                            salesStock.filter(
                              (sale) => sale.size_id === column.size_id
                            )
                          )}
                        </TableCell>
                      ))}
                    <TableCell>
                      <Typography>
                        {sumTotalPrice(
                          salesStock.filter(
                            (s) => s.product_id === p.product_id
                          )
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          );
        })}
    </div>
  );
};
export default WeeklyReport;
