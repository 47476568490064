import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TextField,
} from '@mui/material';
import { StyledTableCell } from '../components/StyledTableCell';
import ProductServices from '../services/product.service';
import { updateCart, removeFromCart } from '../store/actions/cartAction';
import { connect } from 'react-redux';
import StockService from '../services/stock.service';
import { blockInvalidNumber } from './BlockInvalidNumber';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const EditCartDialog = ({ data, updateCart, removeFromCart, onRefresh }) => {
  const [open, setOpen] = React.useState(false);

  const [productStock, setProductStock] = React.useState([]);
  const [cartItems, setCartItems] = React.useState([]);

  const handleClickOpen = async () => {
    const res = await ProductServices.getProductStock(data.product_id);
    setProductStock(res);
    setCartItems(data.items);
    setOpen(true);
  };

  const handleClose = () => {
    setProductStock([]);
    setCartItems([]);
    // setCartData([]);
    setOpen(false);
  };

  const handleChangeStock = (e, stockID) => {
    const getPrevStock = productStock.filter((s) => s.stock_id === stockID);
    const oldQuantity = data.items.filter((item) => item.stock_id === stockID);
    const stockQuantity =
      Number(getPrevStock[0].quantity) + Number(oldQuantity[0].quantity);
    if (e.target.value > stockQuantity) {
      alert('เหลือสินค้าเพียง ' + stockQuantity + ' ชิ้น');
      e.target.value = stockQuantity;
      const quantity = Number(stockQuantity);
      const items = cartItems.map((row) => {
        if (row.stock_id === stockID) {
          return {
            ...row,
            quantity,
          };
        }
        return row;
      });
      setCartItems(items);
    } else {
      const quantity = e.target.value;
      // const quantity = Number(e.target.value);
      const items = cartItems.map((row) => {
        if (row.stock_id === stockID) {
          return {
            ...row,
            quantity,
          };
        }
        return row;
      });
      setCartItems(items);
    }
  };
  const setData = () => {
    return (
      <div>
        <Table sx={{ minWidth: 300 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>สี</StyledTableCell>
              {data.product_size.map((column) => (
                <StyledTableCell key={column.size_id}>
                  {column.size_name}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.colors.map((row) => (
              <TableRow key={row.color_id}>
                <TableCell>{row.color_name}</TableCell>
                {cartItems
                  .filter((stock) => stock.color_id === row.color_id)
                  .map((stock) => (
                    <TableCell key={stock.stock_id}>
                      <TextField
                        value={stock.quantity}
                        type="number"
						onKeyDown={blockInvalidNumber}
                        onChange={(e) => handleChangeStock(e, stock.stock_id)}
                        // onChange={(e) =>
                        //   handleStockUpdate(e, size.stock_id)
                        // }
                      />
                      {/* <TextField
                        type="number"
                        value={stock.quantity}
                        onChange={(e) => handleChangeStock(e, stock.stock_id)}
                      /> */}
                    </TableCell>
                  ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  };

  const updateNewCart = async () => {
    const removedColor = [];
    const removedStock = [];
    // console.log('cartItems', cartItems);
    for (let i = 0; i < data.colors.length; i++) {
      const filter = cartItems.filter(
        (item) => item.color_id === data.colors[i].color_id && item.quantity > 0
      );
      // console.log('filter', filter.length);
      if (filter.length === 0) {
        removedColor.push(data.colors[i]);
        const colorStock = cartItems.filter(
          (item) => item.color_id === data.colors[i].color_id
        );
        removedStock.push(...colorStock);
      }
    }
    if (removedColor.length === data.colors.length) {
      const filter = data.items.filter((item) => item.quantity > 0);
      for (let i = 0; i < filter.length; i++) {
        const stockID = filter[i].stock_id;
        const data = {
          quantity: filter[i].quantity,
        };

        await StockService.incrementStock(stockID, data);
      }
      removeFromCart(data);
      window.location.reload();
    } else {
      for (let i = 0; i < cartItems.length; i++) {
        const stockID = cartItems[i].stock_id;
        const oldQuantity = data.items.filter(
          (item) => item.stock_id === cartItems[i].stock_id
        );
        if (cartItems[i].quantity > oldQuantity[0].quantity) {
          const newData = {
            quantity:
              Number(cartItems[i].quantity) - Number(oldQuantity[0].quantity),
          };
          await StockService.decreaseStock(stockID, newData);
        } else if (cartItems[i].quantity < oldQuantity[0].quantity) {
          const newData = {
            quantity:
              Number(oldQuantity[0].quantity) - Number(cartItems[i].quantity),
          };
          await StockService.incrementStock(stockID, newData);
        }
      }
      if (removedColor.length > 0) {
        const newColor = data.colors.filter(
          (color) => !removedColor.includes(color)
        );
        const newCartItems = cartItems.filter(
          (item) => !removedStock.includes(item)
        );
        const newCartData = {
          colors: newColor,
          items: newCartItems,
          product_id: data.product_id,
          product_name: data.product_name,
          product_size: data.product_size,
        };
        updateCart(newCartData);
      } else {
        const newCartData = {
          colors: data.colors,
          items: cartItems,
          product_id: data.product_id,
          product_name: data.product_name,
          product_size: data.product_size,
        };
        updateCart(newCartData);
      }
    }

    setOpen(false);
  };
  return (
    <div className="space-bt-left">
      <Button onClick={handleClickOpen} style={{color:"red"}}>แก้ไข</Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              แก้ไขตะกร้าสินค้า
            </Typography>
            <Button autoFocus color="inherit" onClick={updateNewCart}>
              บันทึก
            </Button>
          </Toolbar>
        </AppBar>
        <List>{setData()}</List>
      </Dialog>
    </div>
  );
};
export default connect(null, { updateCart, removeFromCart })(EditCartDialog);
