import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import OrderService from '../../../services/order.service';
import {
  Badge,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Grid,
  Typography,
  Button,
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import BillService from '../../../services/bill.service';
import { blockInvalidNumber } from '../../../components/BlockInvalidNumber';
import AddNote from '../../../components/AddnoteDialog';
import AuthService from '../../../services/auth.service';
import Moment from 'moment';

const OrderDetails = () => {
  const { id } = useParams();
  const [orderInfo, setOrderInfo] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const [shippingFee, setShippingFee] = useState([]);

  useEffect(() => {
    const adminAuth = async () => {
      await AuthService.isAdmin();
    };
    const getOrderDetails = async () => {
      const response = await OrderService.getOrderById(id);
      const details = JSON.parse(response.order_details);
      setOrderDetails(details);
      setOrderInfo(response);
    };
    adminAuth();
    getOrderDetails();
  }, [id]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const enable = () => {
    const filterCheckStatus = [];
    for (let j = 0; j < orderDetails.length; j++) {
      const filter = orderDetails[j].items.filter((s) => s.check_status === 0);
      if (filter.length > 0) {
        filterCheckStatus.push(...filter);
      }
    }
    //console.log("filterCheckStatus",filterCheckStatus);
	//console.log("shippingFee",shippingFee);
    if (shippingFee.length > 0 ) {
      return false;
    } else {
      return true;
    }
  };

  const checkBill = async () => {
    const billDetails = [];
    for (let i = 0; i < orderDetails.length; i++) {
      const items = orderDetails[i].items;
      const orderItems = [];
      const filter = items.filter((s) => s.quantity !== 0);
      for (let j = 0; j < filter.length; j++) {
        const stockNote = filter[j].note;
        const newQuantity = Number(filter[j].quantity) - Number(stockNote);
        const stockID = filter[j].stock_id;
        const res = await BillService.getPrice(stockID);
        const totalPrice = Number(newQuantity) * Number(res.price);
        const newStockData = {
          stock_id: stockID,
          description: `${res.color_name} size:${res.size_name}`,
          quantity: Number(newQuantity),
          price: res.price,
          totalPrice: totalPrice,
        };
        orderItems.push(newStockData);
      }
      const data = {
        product_id: orderDetails[i].product_id,
        product_name: orderDetails[i].product_name,
        items: orderItems,
      };
      billDetails.push(data);
    }

    const convert = JSON.stringify(billDetails);
    const currentUser = await AuthService.getCurrentUser();
    const billData = {
      username: orderInfo.username,
      order_id: orderInfo.order_id,
      bill_details: convert,
      shipping_fee: shippingFee,
      author: currentUser.username,
	  ordered_at: Moment(orderInfo.created_at).format('YYYY/MM/DD HH:mm:ss'),
    };
    //console.log('billData', billData);
    try {
      const bill = await BillService.createBill(billData);
      const dataStatus = {
        order_status: 'complete',
        bill_id: bill.bill_id,
      };
      await OrderService.updateStatus(orderInfo.order_id, dataStatus);
      window.location = `/invoice/${bill.bill_id}`;
    } catch (err) {
      console.log(err);
    }
  };
  const checkButton = (stock) => {
    if (stock.check_status === 0 && stock.quantity > 0) {
      return (
        <Grid>
          <Button
            variant="contained"
            style={{ backgroundColor: 'red', height: 36, width: 72 }}
            onClick={(e) => updateCheckStatus(e, stock)}
          ></Button>
        </Grid>
      );
    } else if (stock.check_status === 1) {
      return (
        <Grid>
          <Button
            variant="contained"
            style={{ backgroundColor: '#3CB371', height: 36, width: 72 }}
            disabled={true}
          ></Button>
        </Grid>
      );
    }
  };

  const note = (stock, quantity) => {
    if (quantity > 0) {
      return (
        <Grid item sx={{ marginRight: 2 }}>
          <AddNote
            orders={orderDetails}
            stockData={stock}
            orderID={orderInfo.order_id}
            onRefresh={refreshOrders}
          />
        </Grid>
      );
    }
  };

  const refreshOrders = async () => {
    const response = await OrderService.getOrderById(id);
    const details = JSON.parse(response.order_details);
    setOrderDetails(details);
    setOrderInfo(response);
  };

  const updateCheckStatus = async (e, stockData) => {
    e.preventDefault();
    console.log(stockData);
    const check_status = 1;
    for (let i = 0; i < orderDetails.length; i++) {
      const filter = orderDetails[i].items.filter(
        (s) => s.stock_id === stockData.stock_id
      );
      if (filter.length > 0) {
        const newData = orderDetails[i].items.map((row) => {
          if (row.stock_id === stockData.stock_id) {
            return {
              ...row,
              check_status,
            };
          }
          return row;
        });
        orderDetails[i].items = newData;
        console.log('newData', orderDetails);
        const dataDetails = {
          order_details: JSON.stringify(orderDetails),
        };

        try {
          await OrderService.updateNote(id, dataDetails);
          refreshOrders();
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  return (
    <div className="sarid-container">
      <h1>รายละเอียดคำสั่งซื้อ</h1>
      <h2>คำสั่งซื้อเลขที่: {orderInfo.order_id}</h2>
	  <Grid container sx={{ marginTop: 5 }}>
        <Grid>
          <Typography>คำสั่งซื้อจากคุณ: </Typography>
        </Grid>
        <Grid sx={{ marginLeft: 2 }}>
          <Typography style={{ color: 'blue' }}>
            {orderInfo.username}
          </Typography>
        </Grid>
      </Grid>
	  {orderInfo.customer_name !== null ? (
        <Grid container sx={{ marginTop: 1 }}>
          <Grid>
            <Typography>ชื่อลูกค้า: </Typography>
          </Grid>
          <Grid sx={{ marginLeft: 2 }}>
            <Typography style={{ color: 'purple' }}>
              {orderInfo.customer_name}
            </Typography>
          </Grid>
        </Grid>
      ) : null}
      <Grid container sx={{ marginTop: 1 }}>
        <Grid>
          <Typography>สั่งซื้อวันที่: </Typography>
        </Grid>
        <Grid sx={{ marginLeft: 2 }}>
          <Typography style={{ color: 'blue' }}>
            {Moment(orderInfo.created_at).format('DD/MM/YYYY HH:mm:ss')}
          </Typography>
        </Grid>
      </Grid>
      {orderDetails.map((o) => (
        <div className="cart-item" key={o.product_id}>
          <Grid item xs={12} sm container>
            <Grid item xs container spacing={2}>
              <Grid item sx={{ marginTop: 1, marginBottom: 1 }}>
                <Typography>{o.product_name}</Typography>
              </Grid>
            </Grid>
          </Grid>
		  <div className="table-container">
          <Table sx={{ minWidth: 250 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>สี</StyledTableCell>
                {o.product_size.map((column) => (
                  <StyledTableCell key={column.size_id}>
                    {column.size_name}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {o.colors.map((row) => (
                <TableRow key={row.color_id}>
                  <TableCell>{row.color_name}</TableCell>
                  {o.items
                    .filter((stock) => stock.color_id === row.color_id)
                    .map((size) => (
                      <TableCell key={size.stock_id}>
                        <Grid container rowSpacing={1}>
                          <Grid item xs={2.5}>
                            <Badge badgeContent={size.note} color="secondary">
                              <Typography sx={{ marginTop: 1, marginRight: 1 }}>
                                {size.quantity}
                              </Typography>
                            </Badge>
                          </Grid>
                          <Grid item>
                            <Grid container>
                              {note(size, size.quantity)}
                              {checkButton(size)}
                            </Grid>
                          </Grid>
                        </Grid>
                      </TableCell>
                    ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
		  </div>
        </div>
      ))}
      <Grid item xs={12} sm container>
        <TextField
          type="number"
          label="ค่าส่ง"
          onKeyDown={blockInvalidNumber}
          InputProps={{ inputProps: { min: 0 } }}
          onChange={(e) => setShippingFee(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm container sx={{ marginTop: 4 }}>
        <Button
		  sx={{ mt: 3, mb: 6 }}
          fullWidth
          variant="contained"
          onClick={checkBill}
          disabled={enable()}
        >
          เช็คบิล
        </Button>
      </Grid>
    </div>
  );
};
export default OrderDetails;
