import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  TextField,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import AuthService from '../../services/auth.service';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditCartDialog from '../../components/EditCartDialog';
import OrderService from '../../services/order.service';
import SystemService from '../../services/system.service';
// import StockService from '../../services/stock.service';

const Cart = ({ cartItems }) => {
  const [isCheck, setIsCheck] = useState(false);
  const [cart, setCart] = useState([]);
  const [openWarning, setOpenWarning] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isAdminUser, setisAdminUser] = useState(false);
  const [customerName, setCustomerName] = useState('');
  const [systemData, setSystemData] = useState([]);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  useEffect(() => {
    const getCartItems = async () => {
      if (cartItems.length > 0) {
        setCart(cartItems);
        setIsCheck(true);
      } else {
        setIsCheck(false);
      }
    };
	const getCurrentUser = async () => {
      const currentUser = await AuthService.getCurrentUser();
      if (
        currentUser &&
        currentUser.username !== undefined &&
        currentUser.username === 'admin'
      ) {
        setisAdminUser(true);
      }
    };
	const systemStatus = async () => {
      const res = await SystemService.getSystemStatus();
      setSystemData(res);
    };
    systemStatus();
    getCurrentUser();
    getCartItems();
  }, [cartItems]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const checkCart = () => {
    if (isCheck) {
      return (
        <div className="sarid-container">
          {cart.map((o) => (
            <div className="cart-item" key={o.product_id}>
              <Grid item xs={12} sm container>
                <Grid item xs container spacing={2}>
                  <Grid item sx={{ marginTop: 1, marginBottom: 1 }}>
                    <Typography>{o.product_name}</Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <EditCartDialog data={o} />
                </Grid>
              </Grid>

              <Table sx={{ minWidth: 250 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>สี</StyledTableCell>
                    {o.product_size.map((column) => (
                      <StyledTableCell key={column.size_id}>
                        {column.size_name}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {o.colors.map((row) => (
                    <TableRow key={row.color_id}>
                      <TableCell>{row.color_name}</TableCell>
                      {o.items
                        .filter((stock) => stock.color_id === row.color_id)
                        .map((size) => (
                          <TableCell key={size.stock_id}>
                            {size.quantity}
                          </TableCell>
                        ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          ))}
		  {isAdminUser ? (
            <Grid item xs={12} sm container>
              <TextField
                type="text"
                label="ชื่อลูกค้า"
                onChange={(e) => setCustomerName(e.target.value)}
              />
            </Grid>
          ) : null}
          <Grid item xs={12} sm container>
            <Button
              component={Link}
              to={'/'}
              variant="outlined"
              fullWidth
              sx={{ mt: 3, mb: 2 }}
            >
              เลือกซื้อสินค้าเพิ่มเติม
            </Button>
            <Button
              sx={{ mb: 6 }}
              variant="contained"
              fullWidth
              onClick={checkOut}
			  disabled={systemData.system_status === 1 ? false : true}
            >
              สั่งซื้อสินค้า
            </Button>
          </Grid>
        </div>
      );
    } else {
      return (
        <div className="sarid-container">
          <Card sx={{ minWidth: 300 }}>
            <CardContent>
              <Typography>คุณยังไม่มีสินค้าในตะกร้า</Typography>
            </CardContent>
            <CardActions sx={{ mb: 0 }}>
              <Button
                component={Link}
                to={'/'}
                variant="contained"
                size="small"
                fullWidth
              >
                เลือกซื้อสินค้า
              </Button>
            </CardActions>
          </Card>
        </div>
      );
    }
  };
  const handleCloseWarning = () => {
    setOpenWarning(false);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const unAuth = (e) => {
    e.preventDefault();
    window.location = '/signin';
  };

  const order = async () => {
    const currentUser = await AuthService.getCurrentUser();
    try {
		setOpenBackdrop(true);
		if (currentUser.userId !== undefined) {
        const user = {
          user_id: currentUser.userId,
          username: currentUser.displayName,
          customer_name: null,
        };
        const response = await OrderService.createOrder(user);
        const orderID = response.id;
        //localStorage.removeItem('cartItems');
		setOpenBackdrop(false);
        window.location = `/order/${orderID}`;
      } else {
        if (isAdminUser) {
          const user = {
            user_id: currentUser.user_id,
            username: currentUser.username,
            customer_name: customerName,
          };
          const response = await OrderService.createOrder(user);
          const orderID = response.id;
          //localStorage.removeItem('cartItems');
		  setOpenBackdrop(false);
          window.location = `/order/${orderID}`;
        } else {
          const user = {
            user_id: currentUser.user_id,
            username: currentUser.username,
            customer_name: null,
          };
          const response = await OrderService.createOrder(user);
          const orderID = response.id;
          //localStorage.removeItem('cartItems');
		  setOpenBackdrop(false);
          window.location = `/order/${orderID}`;
        }
      }
       
      } catch (err) {
        console.log(err);
      }
  };

  const checkOut = async () => {
    const isAuth = await AuthService.getCurrentUser();
    if (isAuth === false) {
      setOpenWarning(true);
    } else {
	  setOpenConfirm(true);
    }
  };

  return (
    <div>
      <div className="sarid-container">
        <h1>ตะกร้าสินค้า</h1>
      </div>

      {checkCart()}
      <Dialog
        open={openWarning}
        onClose={handleCloseWarning}
        component="form"
        onSubmit={unAuth}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>ยังไม่ได้เข้าสู่ระบบ</DialogTitle>
        <DialogContent>
          <DialogContentText>
            คุณยังไม่ได้เข้าสู่ระบบ ต้องการเข้าสู่ระบบเพื่อสั่งซื้อสินค้า ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWarning}>ยกเลิก</Button>
          <Button type="submit">ตกลง</Button>
        </DialogActions>
      </Dialog>
 	  <Dialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        // onSubmit={action}
      >
        <DialogTitle>ยืนยันออเดอร์</DialogTitle>
        <DialogContent>
          <DialogContentText>ต้องการยืนยันออเดอร์ ?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirm} autoFocus variant="outlined">
            ยกเลิก
          </Button>
          <Button onClick={order} variant="contained">
            ยืนยัน
          </Button>
        </DialogActions>
      </Dialog>
	  <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};
export default connect((state) => ({
  cartItems: state.cart.cartItems,
}))(Cart);
