import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useParams } from 'react-router';
import ProductServices from '../services/product.service';
import StockService from '../services/stock.service';

export default function AddColor({ productName, onRefresh }) {
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);
  const [newColor, setNewColor] = React.useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const addNewColor = async (e) => {
    e.preventDefault();

    try {
      const data = {
        color_name: newColor,
        product_id: id,
      };
      //Create color
      const response = await ProductServices.createColor(data);
      const colorID = response.id;
      //Create stock
      const colorData = {
        color_id: colorID,
      };
      await StockService.createStock(colorData);
      onRefresh();
      setOpen(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="space-bt">
      <Button fullWidth variant="contained" onClick={handleClickOpen}>
        เพิ่มสี
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        component="form"
        onSubmit={addNewColor}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>เพิ่มสี</DialogTitle>
        <DialogContent>
          <DialogContentText>เพิ่มสีของสินค้า: {productName}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="color"
            label="สี"
            type="text"
            fullWidth
            variant="standard"
            onChange={(event) => setNewColor(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>ยกเลิก</Button>
          <Button type="submit">เพิ่มสี</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
