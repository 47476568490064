import http from '../http-common';
import authHeader from './auth-header';

class UserService {
  getPublicContent() {
    return http.get('/all');
  }

  getUserContent() {
    return http.get('/user', { headers: authHeader() });
  }

  getAdminContent() {
    return http.get('/admin', { headers: authHeader() });
  }
  async updatePhone(userID, info) {
    try {
      const userInfo = await http.put(`/users/${userID}`, info);
      return userInfo.data;
    } catch (err) {
      console.log(err);
    }
  }

  async getDefaultAddress(userID) {
    try {
      const defaultAddress = await http.get(`/users/default-address/${userID}`);
      if (defaultAddress.data.length > 0) {
        return defaultAddress.data;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
    }
  }

  async addNewInformation(userID, info) {
    console.log('info', info);
    try {
      const userInfo = await http.post(`/users/information/${userID}`, info);
      return userInfo.data;
    } catch (err) {
      console.log(err);
    }
  }

  async getUserInformation(userID) {
    try {
      const userInfo = await http.get(`/users/information/${userID}`);
      console.log(userInfo.data);
      if (userInfo.data) {
        return userInfo.data[0];
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
    }
  }

  async getUserAddress(userID) {
    console.log('user id:', userID);
    try {
      const userInfo = await http.get(`/users/address/${userID}`);
      console.log(userInfo.data);
      if (userInfo.data.length > 0) {
        return userInfo.data;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
    }
  }
  async forgetPassword(email) {
    try {
      const data = {
        email: email,
      };
      const res = await http.post('/users/forgetPassword', data);
      if (res.data.length > 0) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
    }
  }
  async addAddress(userID, data) {
    try {
      //Find default address
      if (data.default_address === true) {
        const address = await http.get(`/users/default-address/${userID}`);
        console.log('address data:', address.data);
        if (address.data.length > 0) {
          const addressID = address.data[0].address_id;
          const newData = {
            default_address: false,
          };
          await http.put(`/users/default-address/${addressID}`, newData);
          await http.post(`/users/address/${userID}`, data);
        } else {
          console.log('not found');
          await http.post(`/users/address/${userID}`, data);
        }
      } else {
        await http.post(`/users/address/${userID}`, data);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async deleteAddress(addressID) {
    try {
      await http.delete(`/users/address/${addressID}`);
    } catch (err) {
      console.log(err);
    }
  }

  async updateAddress(userID, data) {
    try {
      //Find default address
      if (data.default_address === true) {
        const address = await http.get(`/users/default-address/${userID}`);
        console.log('address data:', address.data);
        if (address.data.length > 0) {
          const addressID = address.data[0].address_id;
          const newData = {
            default_address: false,
          };
          await http.put(`/users/default-address/${addressID}`, newData);
          await http.put(`/users/address/${data.address_id}`, data);
        } else {
          console.log('not found');
          await http.put(`/users/address/${data.address_id}`, data);
        }
      } else {
        await http.put(`/users/address/${data.address_id}`, data);
      }
    } catch (err) {
      console.log(err);
    }
  }
  async updatePassword(userID, data) {
    try {
      await http.put(`/users/update-password/${userID}`, data);
    } catch (err) {
      console.log(err);
    }
  }
}

export default new UserService();
