import http from '../http-common';

class OrderService {
  async createOrder(user) {
    const cartItems = localStorage.getItem('cartItems');
    const orderData = {
      user_id: user.user_id,
      username: user.username,
      order_details: cartItems,
	  customer_name: user.customer_name,
    };
    try {
      const res = await http.post('/order', orderData);
	  localStorage.removeItem('cartItems');
      return res.data;
    } catch (err) {
      console.log(err);
    }
  }
	
  async deleteOrder(order_id) {
    try {
      await http.delete(`/order/${order_id}`);
    } catch (err) {
      console.log(err);
    }
  }

  async getOrderById(orderID) {
    try {
      const response = await http.get(`/order/${orderID}`);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }
	
  async getOrderByUserId(userID) {
    try {
      const response = await http.get(`/order/user/${userID}`);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }
  async getAllOrders() {
    try {
      const response = await http.get('/order/admin/orders');
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }
  async getAllComplete() {
    try {
      const response = await http.get('/order/admin/orders/complete');
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }
  async updateNote(orderID, dataDetails) {
    try {
      await http.put(`/order/${orderID}`, dataDetails);
    } catch (err) {
      console.log(err);
    }
  }

  async updateStatus(orderID, status) {
    try {
      await http.put(`/order/update-status/${orderID}`, status);
    } catch (err) {
      console.log(err);
    }
  }
}

export default new OrderService();
