import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ColorService from '../services/color.service';

export default function EditColor({ color, color_id, onRefresh }) {
  const [open, setOpen] = React.useState(false);
  const [colorForm, setColorForm] = React.useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateColor = async (e) => {
    e.preventDefault();

    try {
      const colorData = {
        color_name: colorForm,
      };
      await ColorService.updateColor(color_id, colorData);
      onRefresh();
      setOpen(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Button onClick={handleClickOpen}>แก้ไข</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        component="form"
        onSubmit={updateColor}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>แก้ไขสี</DialogTitle>
        <DialogContent>
          <DialogContentText>สีเดิม: {color}</DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="color"
            label="สี"
            type="text"
            fullWidth
            variant="standard"
            onChange={(event) => setColorForm(event.target.value)}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>ยกเลิก</Button>
          <Button type="submit">บันทึก</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
