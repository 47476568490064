import http from '../http-common';

class SystemService {
  async updateSystemStatus(data) {
    try {
      await http.put('/system/update/1', data);
    } catch (err) {
      console.log(err);
    }
  }

  async getSystemStatus() {
    try {
      const response = await http.get('/system/1');
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }
}

export default new SystemService();
