import http from '../http-common';

class ColorDataService {
  findColorByProduct = async (id) => {
    try {
      const response = await http.get(`/products/${id}`);
      return response.data;
    } catch (err) {
      console.log(err);
      return null;
    }
  };
  getAllColor = async () => {
    try {
      const res = await http.get('/colors');
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };
  updateColor = async (colorID, colorData) => {
    try {
      await http.put(`/colors/${colorID}`, colorData);
    } catch (err) {
      console.log(err);
    }
  };
}

export default new ColorDataService();
