import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SalesReportService from '../../services/salesReport.service';
import { StyledTableCell } from '../StyledTableCell';

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
}));
const MonthlyReport = ({ sizeData, colorData, stockData }) => {
  const [month, setMonth] = useState(null);
  const [monthlyReport, setMonthlyReport] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [salesStock, setSalesStock] = useState([]);
  const [topSales, setTopSales] = useState([]);
  const [topOrdered, setTopOrdered] = useState([]);
  const [emptyReport, setEmptyReport] = useState(false);

  const getOrder = async () => {
    try {
      let m = new Date(month).getMonth() + 1;
      let y = month.getFullYear();
      const data = {
        month: m,
        year: y,
      };
      const response = await SalesReportService.getOrderReport(data);
      if (response.length > 0) {
        const products = [];

        for (let o = 0; o < response.length; o++) {
          const order = JSON.parse(response[o].order_details);

          for (let j = 0; j < order.length; j++) {
            const product = order[j];

            if (products.length > 0) {
              const findProduct = products.filter(
                (p) => p.product_id === product.product_id
              );
              if (findProduct.length > 0) {
                products.forEach((x) => {
                  if (x.product_id === product.product_id) {
                    x.product_sales += 1;
                  }
                });
              } else {
                const data = {
                  product_id: product.product_id,
                  product_name: product.product_name,
                  product_sales: 1,
                };
                products.push({ ...data });
              }
            } else {
              const data = {
                product_id: product.product_id,
                product_name: product.product_name,
                product_sales: 1,
              };
              products.push({ ...data });
            }
          }
        }

        const sortTopOrder = products.sort((a, b) =>
          a.product_sales < b.product_sales ? 1 : -1
        );
        setTopOrdered(sortTopOrder);
      }
    } catch (err) {}
  };

  const getSaleReportByMonth = async () => {
    try {
      getOrder();
      let m = new Date(month).getMonth() + 1;
      let y = month.getFullYear();
      const data = {
        month: m,
        year: y,
      };
      const response = await SalesReportService.getReportByMonth(data);
      const reportList = [];
      if (response.length > 0) {
        const products = [];
        var productStock = [];
        var users = [];
        var salesTotalPrice = 0;
        var salesTotalQuantity = 0;
        for (let i = 0; i < response.length; i++) {
          const bill = response[i];
          const billItems = JSON.parse(bill.bill_details);
          const user = {
            username: bill.username,
          };
          const filterUser = users.filter(
            (name) => name.username === bill.username
          );
          if (filterUser.length === 0) {
            users.push(user);
          }
          let billTotal = 0;
          let qTotal = 0;
          for (let j = 0; j < billItems.length; j++) {
            const product = billItems[j];
            const productID = product.product_id;
            const total = subtotal(product.items);
            const totalQ = sumQuantity(product.items);
            billTotal += total;
            qTotal += totalQ;
            if (products.length > 0) {
              const findProduct = products.filter(
                (p) => p.product_id === productID
              );

              if (findProduct.length > 0) {
                products.forEach((x) => {
                  if (x.product_id === product.product_id) {
                    for (let k = 0; k < product.items.length; k++) {
                      const proItem = product.items[k];
                      const filter = x.items.filter(
                        (stock) => stock.stock_id === proItem.stock_id
                      );
                      if (filter.length > 0) {
                        const newQuantity = Number(proItem.quantity);
                        const newData = x.items.map((row) => {
                          if (row.stock_id === proItem.stock_id) {
                            const quantity =
                              Number(row.quantity) + Number(newQuantity);
                            return {
                              ...row,
                              quantity,
                            };
                          }
                          return row;
                        });
                        x.items = newData;
                      } else {
                        x.items.push(proItem);
                      }
                    }
                  }
                });
              } else {
                products.push({ ...product });
                try {
                  const filterStock = stockData.filter(
                    (s) => s.product_id === product.product_id
                  );
                  for (var stp in filterStock) {
                    filterStock[stp].quantity = Number(0);
                    productStock.push({ ...filterStock[stp] });
                  }
                } catch (err) {
                  console.log(err);
                }
              }
            } else {
              products.push({ ...product });
              try {
                const filterStock = stockData.filter(
                  (s) => s.product_id === product.product_id
                );
                for (var st in filterStock) {
                  filterStock[st].quantity = Number(0);
                  productStock.push({ ...filterStock[st] });
                }
              } catch (err) {
                console.log(err);
              }
            }
          }
          salesTotalPrice += billTotal;
          salesTotalQuantity += qTotal;
        }
        const reportData = {
          totalSales: salesTotalPrice,
          totalQuantity: salesTotalQuantity,
          totalOrders: response.length,
          salesItems: products,
          users: users.length,
        };
        for (let s = 0; s < products.length; s++) {
          const items = products[s].items;
          for (let it = 0; it < items.length; it++) {
            const newData = productStock.map((row) => {
              if (row.stock_id === items[it].stock_id) {
                const quantity = Number(items[it].quantity);
                return {
                  ...row,
                  quantity,
                };
              }
              return row;
            });
            productStock = newData;
          }
        }

        reportList.push(reportData);
        setMonthlyReport(reportList);
        setAllProducts(products);
        setSalesStock(productStock);
        const saleProducts = [];
        for (let pro = 0; pro < products.length; pro++) {
          let q = 0;
          const items = products[pro].items;
          for (let st = 0; st < items.length; st++) {
            const item = items[st];
            q += Number(item.quantity);
          }
          const salesData = {
            product_name: products[pro].product_name,
            saleQuantity: q,
          };
          saleProducts.push(salesData);
        }
        const sortSaleProducts = saleProducts.sort((a, b) =>
          a.saleQuantity < b.saleQuantity ? 1 : -1
        );
        setTopSales(sortSaleProducts);
      } else {
        setEmptyReport(true);
        // emptyReport();
      }
    } catch (err) {
      console.log(err);
    }
  };

  function subtotal(items) {
    const total = items
      .map(({ totalPrice }) => totalPrice)
      .reduce((sum, i) => sum + i, 0);
    return total;
  }
  function sumPrice(items) {
    var sum = 0;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.quantity > 0) {
        for (let j = 0; j < allProducts.length; j++) {
          const i = allProducts[j].items;
          const sizePrice = i.filter((s) => s.stock_id === item.stock_id);
          if (sizePrice.length > 0) {
            const price = Number(item.quantity) * Number(sizePrice[0].price);
            sum += price;
            break;
          }
        }
      } else {
        sum += 0;
      }
    }

    return sum;
  }
  function sumTotalPrice(items) {
    var sum = 0;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.quantity > 0) {
        for (let j = 0; j < allProducts.length; j++) {
          const i = allProducts[j].items;
          const sizePrice = i.filter((s) => s.stock_id === item.stock_id);
          if (sizePrice.length > 0) {
            const price = Number(item.quantity) * Number(sizePrice[0].price);
            sum += price;
            break;
          }
        }
      } else {
        sum += 0;
      }
    }

    return sum;
  }
  function sumProductQuantity(items) {
    const productQ = items
      .map(({ quantity }) => quantity)
      .reduce((sum, i) => sum + i, 0);
    return productQ;
  }
  function sumQuantity(items) {
    const q = items
      .map(({ quantity }) => quantity)
      .reduce((sum, i) => sum + i, 0);
    return q;
  }
  const topSalesReport = () => {
    if (topSales.length > 0 && monthlyReport.length > 0) {
      return (
        <div className="sarid-container">
          <Grid container spacing={3}>
            <Grid item>
              <Card sx={{ padding: 3, backgroundColor: '#D7DBDD ' }}>
                <Typography variant="h6">สินค้าขายดี</Typography>
                {topSales.slice(0, 3).map((top, index) => {
                  return (
                    <Grid item key={top.product_name} sx={{ marginTop: 2 }}>
                      <Typography>
                        {index + 1 + '.'}
                        {top.product_name}
                        {' จำนวน ' + top.saleQuantity + ' ตัว'}
                      </Typography>
                      <hr />
                    </Grid>
                  );
                })}
              </Card>
            </Grid>
            <Grid item>
              <Card sx={{ padding: 3, backgroundColor: '#F8C471' }}>
                <Typography variant="h6">สินค้ายอดนิยม</Typography>
                {topOrdered.slice(0, 3).map((top, index) => {
                  return (
                    <Grid item key={top.product_name} sx={{ marginTop: 2 }}>
                      <Typography>
                        {index + 1 + '.'}
                        {top.product_name}
                        {' ออเดอร์ ' + top.product_sales + ' ครั้ง'}
                      </Typography>
                      <hr />
                    </Grid>
                  );
                })}
              </Card>
            </Grid>
          </Grid>
        </div>
      );
    } else if (emptyReport) {
      return (
        <div className="sarid-container">
          <Card sx={{ minWidth: 350 }}>
            <CardContent>
              <Typography>ไม่พบยอดขายในเดือนคุณเลือก</Typography>
            </CardContent>
          </Card>
        </div>
      );
    }
  };
  return (
    <div className="sarid-container">
      <Grid container spacing={3}>
        <Grid item>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={['year', 'month']}
              label="Year and Month"
              minDate={new Date('2022-03-01')}
              maxDate={new Date()}
              value={month}
              onChange={(newValue) => {
                setMonth(new Date(newValue));
              }}
              renderInput={(params) => (
                <TextField {...params} helperText={null} />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item sx={{ marginTop: 1 }}>
          <Button
            variant="contained"
            disabled={month === null}
            onClick={getSaleReportByMonth}
          >
            ดูยอดขาย
          </Button>
        </Grid>
      </Grid>
      
        {monthlyReport.map((val, key) => {
          return (
           
              <Grid container spacing={3}  key={val.totalSales}
            sx={{ marginTop: 5 }}>
                <Grid item xs={12} sm={4} md={3}>
                  <RootStyle
                    sx={{ color: '#145A32', backgroundColor: '#73C6B6' }}
                  >
                    <Typography variant="h5">{val.totalSales.toLocaleString()}</Typography>
                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                      บาท
                    </Typography>
                  </RootStyle>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <RootStyle
                    sx={{ color: '#145A32', backgroundColor: '#85C1E9' }}
                  >
                    <Typography variant="h5">{val.totalQuantity.toLocaleString()}</Typography>
                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                      ตัว
                    </Typography>
                  </RootStyle>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <RootStyle
                    sx={{ color: '#145A32', backgroundColor: '#C39BD3 ' }}
                  >
                    <Typography variant="h5">{val.totalOrders}</Typography>
                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                      ออเดอร์
                    </Typography>
                  </RootStyle>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <RootStyle
                    sx={{ color: '#145A32', backgroundColor: '#D98880 ' }}
                  >
                    <Typography variant="h5">{val.users}</Typography>
                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                      ผู้ใช้
                    </Typography>
                  </RootStyle>
                </Grid>
              </Grid>
          
          );
        })}
      

      <br />

      {topSalesReport()}

      {allProducts.map((p) => {
        return (
          <div className="table-sales" key={p.product_id}>
            <Typography>{p.product_name}</Typography>
            <Table sx={{ minWidth: 250, border: 0.5, borderRadius: 1 }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell>สี</StyledTableCell>
                  {sizeData
                    .filter((size) => size.product_id === p.product_id)
                    .map((column) => (
                      <StyledTableCell key={column.size_id}>
                        {column.size_name}
                      </StyledTableCell>
                    ))}
                  <StyledTableCell>รวมเป็นเงิน</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {colorData
                  .filter((color) => color.product_id === p.product_id)
                  .map((row) => (
                    <TableRow key={row.color_id}>
                      <TableCell>{row.color_name}</TableCell>
                      {salesStock
                        .filter((stock) => stock.color_id === row.color_id)
                        .map((size) => (
                          <TableCell key={size.stock_id}>
                            <Typography sx={{ marginTop: 1, marginRight: 1 }}>
                              {size.quantity}
                            </Typography>
                          </TableCell>
                        ))}
                      <TableCell>
                        <Typography>
                          {sumPrice(
                            salesStock.filter(
                              (stock) => stock.color_id === row.color_id
                            )
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                <TableRow sx={{ backgroundColor: '#F7DC6F' }}>
                  <TableCell>
                    <Typography>รวม</Typography>
                  </TableCell>
                  {sizeData
                    .filter((size) => size.product_id === p.product_id)
                    .map((column) => (
                      <TableCell key={column.size_id}>
                        {sumProductQuantity(
                          salesStock.filter(
                            (sale) => sale.size_id === column.size_id
                          )
                        )}
                      </TableCell>
                    ))}
                  <TableCell>
                    <Typography>
                      {sumTotalPrice(
                        salesStock.filter((s) => s.product_id === p.product_id)
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        );
      })}
    </div>
  );
};
export default MonthlyReport;
