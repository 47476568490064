import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function WarningCancelDialog({
  title,
  message,
  data,
  button,
  onWarningSubmit,
}) {
  const [open, setOpen] = React.useState(false);

  const action = () => {
    onWarningSubmit();
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button style={{ color: 'red' }} onClick={handleClickOpen}>
        ยกเลิก
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message} {data} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            ยกเลิก
          </Button>
          <Button onClick={action} variant="contained">
            {button}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
