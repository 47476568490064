import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import OrderService from '../../../../services/order.service';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  Grid,
  Typography,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import AuthService from '../../../../services/auth.service';
import Moment from 'moment';

const OrderHistoryDetails = () => {
  const { id } = useParams();
  const [orderInfo, setOrderInfo] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);

  useEffect(() => {
    const adminAuth = async () => {
      await AuthService.isAdmin();
    };
    const getOrderDetails = async () => {
      const response = await OrderService.getOrderById(id);
      const details = JSON.parse(response.order_details);
      setOrderDetails(details);
      setOrderInfo(response);
    };
    adminAuth();
    getOrderDetails();
  }, [id]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  return (
    <div className="sarid-container">
      <h1>รายละเอียดคำสั่งซื้อ</h1>
      <h2>คำสั่งซื้อเลขที่: {orderInfo.order_id}</h2>
      <Grid container sx={{ marginTop: 5 }}>
        <Grid>
          <Typography>คำสั่งซื้อจากคุณ: </Typography>
        </Grid>
        <Grid sx={{ marginLeft: 2 }}>
          <Typography style={{ color: 'blue' }}>
            {orderInfo.username}
          </Typography>
        </Grid>
      </Grid>
      {orderInfo.customer_name !== null ? (
        <Grid container sx={{ marginTop: 1 }}>
          <Grid>
            <Typography>ชื่อลูกค้า: </Typography>
          </Grid>
          <Grid sx={{ marginLeft: 2 }}>
            <Typography style={{ color: 'purple' }}>
              {orderInfo.customer_name}
            </Typography>
          </Grid>
        </Grid>
      ) : null}
      <Grid container sx={{ marginTop: 1 }}>
        <Grid>
          <Typography>สั่งซื้อวันที่: </Typography>
        </Grid>
        <Grid sx={{ marginLeft: 2 }}>
          <Typography style={{ color: 'blue' }}>
            {Moment(orderInfo.created_at).format('DD/MM/YYYY HH:mm:ss')}
          </Typography>
        </Grid>
      </Grid>
      {orderDetails.map((o) => (
        <div className="cart-item" key={o.product_id}>
          <Grid item xs={12} sm container>
            <Grid item xs container spacing={2}>
              <Grid item sx={{ marginTop: 1, marginBottom: 1 }}>
                <Typography>{o.product_name}</Typography>
              </Grid>
            </Grid>
          </Grid>
		  <div className="table-container">
          <Table sx={{ minWidth: 250 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>สี</StyledTableCell>
                {o.product_size.map((column) => (
                  <StyledTableCell key={column.size_id}>
                    {column.size_name}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {o.colors.map((row) => (
                <TableRow key={row.color_id}>
                  <TableCell>{row.color_name}</TableCell>
                  {o.items
                    .filter((stock) => stock.color_id === row.color_id)
                    .map((size) => (
                      <TableCell key={size.stock_id}>{size.quantity}</TableCell>
                    ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
		  </div>
        </div>
      ))}

      <Grid item xs={12} sm container sx={{ marginTop: 4 }}>
        <Button
		  sx={{ mt: 3, mb: 6 }}
          fullWidth
          variant="contained"
          component={Link}
          to={`/invoice/${orderInfo.bill_id}`}
        >
          ดูใบเสร็จ
        </Button>
      </Grid>
    </div>
  );
};
export default OrderHistoryDetails;
