import React, { useEffect, useState } from 'react';
import ShopService from '../../services/shop.service';
import { Grid, Typography } from '@mui/material';
import EditShopInfoDialog from '../../components/EditShopInfoDialog';
import AuthService from '../../services/auth.service';

const ShopInformation = () => {
  const [shopInfo, setShopInfo] = useState([]);
  useEffect(() => {
    const adminAuth = async () => {
      await AuthService.isAdmin();
    };
    const getInfo = async () => {
      const response = await ShopService.getInfo();
      setShopInfo(response);
    };
    adminAuth();
    getInfo();
  }, []);

  const refreshInfo = async () => {
    const response = await ShopService.getInfo();
    setShopInfo(response);
  };
  return (
    <div className="sarid-container">
      <h1>รายละเอียดหน้าแรก</h1>
      <div className="information-container">
        <Grid>
          <Typography>[ข้อมูล1]: {shopInfo.info1}</Typography>
        </Grid>
        <Grid>
          <Typography>[ข้อมูล2]: {shopInfo.info2}</Typography>
        </Grid>
        <Grid>
          <Typography>[ข้อมูล3]: {shopInfo.info3}</Typography>
        </Grid>
      </div>
      <div className="sarid-button">
        <EditShopInfoDialog onRefresh={refreshInfo} />
      </div>
    </div>
  );
};

export default ShopInformation;
