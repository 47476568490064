import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import DailyReport from '../../../components/report/DailyReport';
import WeeklyReport from '../../../components/report/WeeklyReport';
import MonthlyReport from '../../../components/report/MonthlyReport';
import ProductServices from '../../../services/product.service';
import ColorService from '../../../services/color.service';
import StockService from '../../../services/stock.service';
import AuthService from '../../../services/auth.service';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const SalesReport = () => {
  const [value, setValue] = React.useState(0);
  const [sizeData, setSizeData] = useState([]);
  const [colorData, setColorData] = useState([]);
  const [stockData, setStockData] = useState([]);

  useEffect(() => {
    const bossAuth = async () => {
      await AuthService.isBoss();
    };
    const getData = async () => {
      await ProductServices.getAllProductSize().then((res) => {
        setSizeData(res);
      });
      await ColorService.getAllColor().then((res) => {
        setColorData(res);
      });
      await StockService.getAllStock().then((res) => {
        setStockData(res);
      });
    };
    bossAuth();
    getData();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="sarid-container">
      <h1>ยอดขาย</h1>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="sarid-report-tab"
          >
            <Tab label="รายวัน" {...a11yProps(0)} />
            <Tab label="รายสัปดาห์" {...a11yProps(1)} />
            <Tab label="รายเดือน" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <DailyReport
            sizeData={sizeData}
            stockData={stockData}
            colorData={colorData}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <WeeklyReport
            sizeData={sizeData}
            stockData={stockData}
            colorData={colorData}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <MonthlyReport
            sizeData={sizeData}
            stockData={stockData}
            colorData={colorData}
          />
        </TabPanel>
      </Box>
    </div>
  );
};
export default SalesReport;
