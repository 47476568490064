import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  Button,
  Grid,
  Typography,
} from '@mui/material';
import ProductServices from '../../../services/product.service';
import AuthService from '../../../services/auth.service';

const AddStock = () => {
  const { id } = useParams();
  const [productInfo, setProductInfo] = useState([]);
  const [productSize, setProductSize] = useState([]);
  const [productColor, setProductColor] = useState([]);
  const [productStock, setProductStock] = useState([]);
  useEffect(() => {
    const adminAuth = async () => {
      await AuthService.isAdmin();
    };
    const getProSize = async () => {
      await ProductServices.getProductSize(id).then((response) => {
        setProductSize(response);
      });
    };

    const getProStock = async () => {
      await ProductServices.getProductStock(id).then((response) => {
        setProductStock(response);
      });
    };
    const getProColor = async () => {
      await ProductServices.getProductColor(id).then((response) => {
        setProductColor(response);
      });
    };
    const getProInfo = async () => {
      await ProductServices.getProductInfo(id).then((response) => {
        setProductInfo(response);
      });
    };
    adminAuth();
    getProInfo();
    getProSize();
    getProColor();
    getProStock();
  }, [id]);
  const addProductStock = async () => {
    for (let i = 0; i < productStock.length; i++) {
      const stockID = productStock[i].stock_id;
      const stockQuantity = productStock[i].quantity;
      await ProductServices.updateStock(stockID, stockQuantity);
    }
    window.location = '/admin';
  };
  const handleOrderUpdate = (e, stock_id) => {
    const quantity = e.target.value;
    const newData = productStock.map((row) => {
      if (row.stock_id === stock_id) {
        return {
          ...row,
          quantity,
        };
      }
      return row;
    });
    setProductStock(newData);
  };

  return (
    <div className="sarid-container">
      <Grid>
        <Typography>{productInfo.name}</Typography>
      </Grid>
      <Table sx={{ minWidth: 400 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>สี</TableCell>
            {productSize.map((column) => (
              <TableCell key={column.size_id}>{column.size_name}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {productColor.map((row) => (
            <TableRow key={row.color_id}>
              <TableCell>{row.color_name}</TableCell>
              {productStock
                .filter((stock) => stock.color_id === row.color_id)
                .map((size) => (
                  <TableCell key={size.stock_id}>
                    <TextField
                      type="text"
                      onChange={(e) => handleOrderUpdate(e, size.stock_id)}
                    />
                  </TableCell>
                ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Grid>
        <Button sx={{ mt: 3, mb: 6 }} onClick={addProductStock} variant="contained">
          เพิ่มในคลังสินค้า
        </Button>
      </Grid>
    </div>
  );
};
export default AddStock;
