import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import OrderService from '../services/order.service';
import { blockInvalidNumber } from './BlockInvalidNumber';

export default function AddNote({ orderID, stockData, orders, onRefresh }) {
  const [open, setOpen] = React.useState(false);
  const [newNote, setNewNote] = React.useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const addNewNote = async (e) => {
    e.preventDefault();
    const note = Number(newNote);
    for (let i = 0; i < orders.length; i++) {
      const filter = orders[i].items.filter(
        (s) => s.stock_id === stockData.stock_id
      );
      if (filter.length > 0) {
        const newData = orders[i].items.map((row) => {
          if (row.stock_id === stockData.stock_id) {
            return {
              ...row,
              note,
            };
          }
          return row;
        });
        orders[i].items = newData;

        const dataDetails = {
          order_details: JSON.stringify(orders),
        };

        try {
          await OrderService.updateNote(orderID, dataDetails);
          onRefresh();
          setOpen(false);
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
       	Note
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        component="form"
        onSubmit={addNewNote}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>เพิ่มโน๊ต</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>เพิ่มสีของสินค้า: {productName}</DialogContentText> */}
          <TextField
            required
            autoFocus
            margin="dense"
            id="color"
            label="จำนวนที่ค้าง"
            type="number"
            fullWidth
            variant="standard"
			onKeyDown={blockInvalidNumber}
            onChange={(event) => setNewNote(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>ยกเลิก</Button>
          <Button type="submit">เพิ่มโน๊ต</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
