import http from '../http-common';
class ShopService {
  async getInfo() {
    try {
      const response = await http.get('/shop-info');
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }
  async updateInfo(data) {
    try {
      await http.put(`/shop-info`, data);
    } catch (err) {
      console.log(err);
    }
  }
}
export default new ShopService();
