import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import Link from '@mui/material/Link';
import ListItemText from '@mui/material/ListItemText';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Slide from '@mui/material/Slide';
import {
  TextField,
  Grid,
  Container,
  Box,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import validator from 'validator';
import UserService from '../services/user.service';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ForgotPasswordDialog() {
  const [open, setOpen] = React.useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [email, setEmail] = useState([]);
  const [emailError, setEmailError] = useState('');

  const handleClickOpen = () => {
    setEmailError('');
    setOpen(true);
  };

  const handleClose = () => {
    setEmailError('');
    setOpen(false);
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
    handleClose();
  };

  const validateEmail = (e) => {
    console.log('email:');

    if (validator.isEmail(email)) {
      setEmailError('');
      return true;
    } else {
      setEmailError('รูปแบบอีเมลไม่ถูกต้อง!');
      return false;
    }
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    setEmailError('');
    const validate = validateEmail(email);
    if (validate) {
      const response = await UserService.forgetPassword(email);

      if (response === false) {
        setEmailError('อีเมลนี้ยังไม่ได้ลงทะเบียน กรุณาลองใหม่อีกครั้ง');
      } else {
        setOpenSuccess(true);
      }
      console.log('response:', response);
    }
  };

  return (
    <div>
      <Link onClick={handleClickOpen} variant="body2">
        ลืมรหัสผ่าน?
      </Link>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <ArrowBackIosNewIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              ลืมรหัสผ่าน
            </Typography>
          </Toolbar>
        </AppBar>
        <List>
          <div className="sarid-container">
            <ListItemText
              primary="ต้องการรีเซ็ตรหัสผ่านใหม่"
              secondary="กรุณากรอกที่อยู่อยู่อีเมลที่คุณลงทะเบียนไว้ เพื่อรับการร้องขอรีเซ็ตรหัสผ่าน"
            />
          </div>
        </List>
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 4,
            }}
            component="form"
          >
            <List>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="อีเมล"
                    type="email"
                    name="email"
                    autoComplete="email"
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </Grid>
              </Grid>
            </List>
            <List>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <span
                    style={{
                      color: 'red',
                    }}
                  >
                    {emailError}
                  </span>
                </Grid>
              </Grid>
            </List>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={sendEmail}
            >
              รีเซ็ตรหัสผ่าน
            </Button>
          </Box>
        </Container>
      </Dialog>
      <Dialog
        open={openSuccess}
        onClose={handleCloseSuccess}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'ส่งคำร้องสำเร็จ'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ระบบได้ส่งคำร้องขอรีเซ็ตรหัสผ่านไปยังอีเมล {email}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSuccess} autoFocus>
            ตกลง
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
