import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import AuthService from '../services/auth.service';

const theme = createTheme();
const eye = <FontAwesomeIcon icon={faEye} />;
const eyeClose = <FontAwesomeIcon icon={faEyeSlash} />;

export default function SignUp() {
  const formRef = React.useRef();
  const [confirmPassErr, setConfirmPassErr] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassWord] = React.useState('');
  const [confirmPass, setConfirmPass] = React.useState('');
  const [passwordShown, setPasswordShown] = React.useState(false);
  const [confirmPassShown, setConfirmPassShown] = React.useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const isValid = formRef.current.reportValidity();
    if (isValid) {
      await AuthService.register(email, password);
    }
  };
  const handleChangePass = (e) => {
    const pass = e.target.value;
    if (confirmPass !== '') {
      if (password === confirmPass) {
        setConfirmPassErr('');
      } else {
        setConfirmPassErr('รหัสผ่านไม่ตรงกัน');
      }
    }
    setPassWord(pass);
  };

  const handleChangeConfirmPass = (e) => {
    const confirmPass = e.target.value;
    setConfirmPass(confirmPass);
    if (password !== '' || password !== undefined) {
      if (password === confirmPass) {
        setConfirmPassErr('');
      } else {
        setConfirmPassErr('รหัสผ่านไม่ตรงกัน');
      }
    }
  };
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const toggleConfirmPassVisiblity = () => {
    setConfirmPassShown(confirmPassShown ? false : true);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            สมัครสมาชิก
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
            ref={formRef}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="อีเมล"
                  type="email"
                  name="email"
                  autoComplete="email"
                  onChange={(event) => setEmail(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <div className="pass-wrapper">
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label="รหัสผ่าน"
                    type={passwordShown ? 'text' : 'password'}
                    id="password"
                    onChange={handleChangePass}
                  />
                  <i onClick={togglePasswordVisiblity}>
                    {passwordShown ? eyeClose : eye}
                  </i>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="pass-wrapper">
                  <TextField
                    required
                    fullWidth
                    name="confirm-password"
                    label="ยืนยันรหัสผ่าน"
                    type={confirmPassShown ? 'text' : 'password'}
                    id="confirm-password"
                    onChange={handleChangeConfirmPass}
                  />
                  <i onClick={toggleConfirmPassVisiblity}>
                    {confirmPassShown ? eyeClose : eye}
                  </i>
                </div>
              </Grid>
            </Grid>
            <span style={{ color: 'red' }}>{confirmPassErr}</span>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => formRef.current.reportValidity()}
            >
              สมัครสมาชิก
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/signin" variant="body2">
                  สมัครสมาชิกแล้ว? เข้าสู่ระบบ
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
