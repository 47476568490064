import { Grid, Typography, Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SystemService from '../../../services/system.service';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import AuthService from '../../../services/auth.service';

const SaleSystem = () => {
  const [openWarning, setOpenWarning] = useState(false);
  const [systemData, setSystemData] = useState([]);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  useEffect(() => {
    const adminAuth = async () => {
      await AuthService.isAdmin();
    };
    const getStatus = async () => {
      const res = await SystemService.getSystemStatus();
      setSystemData(res);
    };
    adminAuth();
    getStatus();
  }, []);

  const openSystem = async (e) => {
    e.preventDefault();
    setOpenWarning(true);
    setTitle('เปิดระบบ');
    setMessage('คุณแน่ใจว่าต้องการเปิดระบบซื้อขาย ?');
  };
  const reloadSystem = async () => {
    const res = await SystemService.getSystemStatus();
    setSystemData(res);
  };

  const closeSystem = async (e) => {
    setOpenWarning(true);
    setTitle('ปิดระบบ');
    setMessage('คุณแน่ใจว่าต้องการปิดระบบซื้อขาย ?');
  };

  const handleCloseWarning = () => {
    setOpenWarning(false);
  };

  const setting = async () => {
    if (systemData.system_status === 1) {
      const data = {
        system_status: false,
      };
      try {
        await SystemService.updateSystemStatus(data);
        setOpenWarning(false);
        reloadSystem();
      } catch (err) {
        console.log(err);
      }
    } else {
      const data = {
        system_status: true,
      };
      try {
        await SystemService.updateSystemStatus(data);
        setOpenWarning(false);
        reloadSystem();
      } catch (err) {
        console.log(err);
      }
    }
  };

  const status = () => {
    if (systemData.system_status === 1) {
      return (
        <div className="">
          <Grid>
            <Typography>สถานะ: เปิดการซื้อขาย</Typography>
          </Grid>
          <Button
            sx={{ marginTop: 4, backgroundColor: 'red' }}
            fullWidth
            variant="contained"
            onClick={closeSystem}
          >
            ปิดการซื้อขาย
          </Button>
        </div>
      );
    } else {
      return (
        <div className="">
          <Grid>
            <Typography>สถานะ: ปิดการซื้อขาย</Typography>
          </Grid>
          <Button
            sx={{ marginTop: 4 }}
            fullWidth
            variant="contained"
            onClick={openSystem}
          >
            เปิดการซื้อขาย
          </Button>
        </div>
      );
    }
  };
  return (
    <div className="sarid-container">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Grid>
          <Typography sx={{ fontSize: '2rem' }}>ระบบซื้อขายสินค้า</Typography>
        </Grid>
        {status()}
      </Box>
      <Dialog
        open={openWarning}
        onClose={handleCloseWarning}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // onSubmit={action}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWarning} autoFocus>
            ยกเลิก
          </Button>
          <Button onClick={setting}>ตกลง</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default SaleSystem;
